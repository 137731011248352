/**
 *
 * COLORS: VARS
 *
 */

$colors: (
	'primary-1':  			#0C6196,
	'primary-1-light': 		#137cbd,

	'secondary-1': 			#99BACB,
	'secondary-1-light':	#F8FCFE,

	'secondary-2': 			#31a175,
	'secondary-2-light': 	#42ad83,

	'gray-1':				#444444,
	'gray-2':				#717477,
	'gray-3':				#8a8d8f,
	'gray-4':				#97999b,
	'gray-5':				#a5a7a8,
	'gray-6':				#b8b9bb,
	'gray-7':				#c6c7c9,
	'gray-8':				#d4d5d6,
	'gray-9':				#e3e3e4,
	'gray-10':				#f0f1f1,

	'error':				#D8393C,
	'error-fill': 			#FAE5E7,

	'warning':				#FF9904,
	'warning-fill':			#fdedd6,

	'success': 				#4a9864,
	'success-fill': 		#c4decd,

	'white': 				#ffffff
);