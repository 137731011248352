.form { width: 100%;

	.form__field { width: 100%; margin: 15px 0 0 0;

		&:first-child { margin-top: 0; }

		&.gap-small { margin-top: 10px; }
		&.gap-large { margin-top: 40px; }



		/*
		 *
		 *
		 *
		 * Define spacings */
		&.spacing-b-0 { margin-bottom: 0; }
		&.spacing-b-xxs { margin-bottom: 5px; }
		&.spacing-b-xs { margin-bottom: 10px; }
		&.spacing-b-s { margin-bottom: 15px; }
		&.spacing-b-m { margin-bottom: 20px; }
		&.spacing-b-l { margin-bottom: 25px; }
		&.spacing-b-xl { margin-bottom: 30px; }
		&.spacing-b-xxl { margin-bottom: 35px; }

	}

}