@import "../../../01_fundaments/utils";

.RC__filters { display: flex; align-items: center; justify-content: flex-start; flex-wrap: wrap; margin: 0 -2px 5px; }

.RC__filter { display: inline-flex; align-items: center; justify-content: flex-start; font-family: font('main'); padding: 0 0 0 15px; margin: 4px; background: #f0f6ff; color: color('gray-1'); border-radius: 7px; border: none; appearance: none; overflow: hidden; cursor: pointer; transition: all 150ms; @include noselect();

    .RC__filterText { font-size: 12px; }

    .RC__filterIcon { display: flex; padding: 7px; align-items: center; justify-content: center; background: #e5eefa; margin: 0 0 0 15px; transition: all 150ms;
        
        i { font-size: 19px; color: color('primary-1'); }

    }

    &:focus, &:active { outline: none; }

    &:hover { background: #e5eefa;
        
        .RC__filterIcon { background: #d6e1f1; }
    
    }

}