.reconciliation {

    .reconciliation__statementHeader { display: flex; align-items: flex-start; justify-content: space-between; padding: 20px; }

    .reconciliation__statementBalance { display: flex; align-items: center; justify-content: flex-end; }

    .reconciliation__statementBasics { padding: 5px 0 0 0; }

    .reconciliation__statementBalanceItem { margin: 0 0 0 35px; text-align: right;

        &:first-child { margin-left: 0; }

    }

    .reconciliation__statementProgress { width: 100%; height: 4px; background: #f0f0f0; border-radius: 10px; overflow: hidden; position: relative;
        
        &:after { content: ""; display: block; width: 0%; height: 100%; background: color('success'); position: absolute; left: 0; top: 0; transition: all 150ms; }

        @for $i from 1 through 100 {

            &[data-progress='#{$i}']:after { width: ($i * 1%); }

        }
    
    }

}










/*
 *
 * Media Queries
 *
 */

/*
 * Layout 03 - - - 768px - 1023px */
@media all  and (max-width: map-get($breakpoints, 'large')) {

	.reconciliation {

		.reconciliation__statementBalanceItem { margin-left: 20px;
			
			&:first-child { margin-left: 0; }
		
		}
		

	}

}