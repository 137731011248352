.reconciliation { 

    .reconciliation__statementList { display: none; padding: 0 20px 20px 20px; }

	.reconciliation__transaction { display: flex; align-items: center; justify-content: space-between; padding: 12px 20px; background: #f5f5f5; margin: 2px 0 0 0;
		
		.paragraph { padding-top: 0; }

		&:nth-child(even) { background: #faf9f9; }
		&:first-child { margin-top: 0; }

		&.is-active { background: color('gray-9'); }
	
	}

	.reconciliation__transactionDefaults { width: 48%; padding: 0 50px 0 0; }

	.reconciliation__transactionLine { display: flex; align-items: center; justify-content: flex-start;
		
		strong { display: inline-block; margin: 0 3px 0 0; flex-shrink: 0; font-weight: 400; color: color('gray-2'); }

		.ellipsis { overflow: hidden; }
	
	}

	.reconciliation__transactionAttributes { display: flex; width: 32%; align-items: center; justify-content: flex-start; }

	.reconciliation__transactionAttribute { display: flex; align-items: center; justify-content: flex-start; margin: 0 0 0 25px; cursor: pointer; @include noselect();
		
		.control { margin: 0 5px 0 0; }

		&:first-child { margin-left: 0; }

		&.is-disabled { opacity: 0.5; }
	
	}

	.reconciliation__transactionActions { width: 20%; text-align: right;
		
		a { color: color('gray-5'); }
	
	}

	.reconciliation__actionInfo { display: flex; align-items: center; justify-content: flex-end; margin: 0 0 2px 0;
		
		.control { margin-left: 10px; }

    }	
    
}










/*
 *
 * Media Queries
 *
 */

/*
 * Layout 03 - - - 768px - 1023px */
@media all  and (max-width: map-get($breakpoints, 'large')) {

	.reconciliation {

		.reconciliation__transactionAttribute {

			.paragraph { display: none; }

		}
		

	}

}