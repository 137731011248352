/**
 *
 * TYPOGRAPHY: FUNCTIONS
 *
 */

@function font($face: main, $weight: 'normal') {

	$font: map-get($fonts, quote($face));

	@return map-get($font, quote($weight)), Helvetica, Arial, serif;

}

@mixin noselect() {

	-webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;

}