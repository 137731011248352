.brand { display: inline-flex;

    svg { display: block; width: 25px; height: 25px; }





    /*
     *
     * Define variations */
    &.size-xs svg { width: 15px; height: 15px; }
    &.size-s svg { width: 20px; height: 20px; }
    &.size-m svg { width: 25px; height: 25px; }
    &.size-l svg { width: 30px; height: 30px; }
    &.size-xl svg { width: 35px; height: 35px; }





}