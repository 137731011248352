.confirmation { position: relative;

    .confirmation__inner { }

    .confirmation__trigger {

    }

    .confirmation__message { display: none; min-width: 400px; text-align: left; position: absolute; right: 0; top: 0; z-index: 10; box-shadow: 0 0 350px rgba(0, 0, 0, 0.25); border-radius: 4px 4px 0 0; }



    /*
     *
     * Define states */
    &.is-open {

        .confirmation__trigger { pointer-events: none; opacity: 0; visibility: hidden; }

        .confirmation__message { display: block; }

    }



    /*
     *
     * Define positions */
    &.align-left .confirmation__message { left: 0; right: auto; }
    &.align-center .confirmation__message { left: 50%; right: auto; transform: translateX(-50%); }
    &.align-right .confirmation__message { left: auto; right: 0; }

    &.valign-top .confirmation__message { top: 0; bottom: auto; }
    &.valign-middle .confirmation__message { top: 50%; bottom: auto; transform: translateY(-50%); }
    &.valign-bottom .confirmation__message { top: auto; bottom: 0; }

}