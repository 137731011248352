.moduleHeader { margin: 0 0 25px 0;

    .moduleHeader__search { display: flex; justify-content: flex-end; position: relative; }

    .moduleHeader__searchClose { position: absolute; right: -25px; bottom: 4px; opacity: 0.4;

        &:hover { opacity: 1; }

    }

}










/*
 *
 * Media Queries
 *
 */

/*
 * Custom layout */
@media all and (max-width: 1320px) {

    .moduleHeader {

        .moduleHeader__searchClose { right: 2px; left: auto; top: -22px; bottom: auto; }

    }

}