/**
 *
 * BOXING: VARS
 *
 */
/**
 *
 * BOXING: FUNCTIONS
 *
 */
/**
 *
 * BOXING: MIXINS
 *
 */
/**
 *
 * COLORS: VARS
 *
 */
/**
 *
 * COLORS: FUNCTIONS
 *
 */
/**
 *
 * COLORS: VARS
 *
 */
/**
 *
 * COLORS: FUNCTIONS
 *
 */
/**
 *
 * COLORS: INLINES
 *
 */
.fc--primary-1 {
  color: #0C6196 !important;
  fill: #0C6196 !important; }

.fc--primary-1-darker {
  color: #094c75 !important;
  fill: #094c75 !important; }

.fc--primary-1-lighter {
  color: #0f76b7 !important;
  fill: #0f76b7 !important; }

.bgc--primary-1 {
  background-color: #0C6196 !important; }

.bgc--primary-1-darker {
  background-color: #094c75 !important; }

.bgc--primary-1-lighter {
  background-color: #0f76b7 !important; }

.fc--primary-1-light {
  color: #137cbd !important;
  fill: #137cbd !important; }

.fc--primary-1-light-darker {
  color: #10679d !important;
  fill: #10679d !important; }

.fc--primary-1-light-lighter {
  color: #1691dd !important;
  fill: #1691dd !important; }

.bgc--primary-1-light {
  background-color: #137cbd !important; }

.bgc--primary-1-light-darker {
  background-color: #10679d !important; }

.bgc--primary-1-light-lighter {
  background-color: #1691dd !important; }

.fc--secondary-1 {
  color: #99BACB !important;
  fill: #99BACB !important; }

.fc--secondary-1-darker {
  color: #81aabf !important;
  fill: #81aabf !important; }

.fc--secondary-1-lighter {
  color: #b1cad7 !important;
  fill: #b1cad7 !important; }

.bgc--secondary-1 {
  background-color: #99BACB !important; }

.bgc--secondary-1-darker {
  background-color: #81aabf !important; }

.bgc--secondary-1-lighter {
  background-color: #b1cad7 !important; }

.fc--secondary-1-light {
  color: #F8FCFE !important;
  fill: #F8FCFE !important; }

.fc--secondary-1-light-darker {
  color: #d9effa !important;
  fill: #d9effa !important; }

.fc--secondary-1-light-lighter {
  color: white !important;
  fill: white !important; }

.bgc--secondary-1-light {
  background-color: #F8FCFE !important; }

.bgc--secondary-1-light-darker {
  background-color: #d9effa !important; }

.bgc--secondary-1-light-lighter {
  background-color: white !important; }

.fc--secondary-2 {
  color: #31a175 !important;
  fill: #31a175 !important; }

.fc--secondary-2-darker {
  color: #298661 !important;
  fill: #298661 !important; }

.fc--secondary-2-lighter {
  color: #39bc89 !important;
  fill: #39bc89 !important; }

.bgc--secondary-2 {
  background-color: #31a175 !important; }

.bgc--secondary-2-darker {
  background-color: #298661 !important; }

.bgc--secondary-2-lighter {
  background-color: #39bc89 !important; }

.fc--secondary-2-light {
  color: #42ad83 !important;
  fill: #42ad83 !important; }

.fc--secondary-2-light-darker {
  color: #38936f !important;
  fill: #38936f !important; }

.fc--secondary-2-light-lighter {
  color: #55be95 !important;
  fill: #55be95 !important; }

.bgc--secondary-2-light {
  background-color: #42ad83 !important; }

.bgc--secondary-2-light-darker {
  background-color: #38936f !important; }

.bgc--secondary-2-light-lighter {
  background-color: #55be95 !important; }

.fc--gray-1 {
  color: #444444 !important;
  fill: #444444 !important; }

.fc--gray-1-darker {
  color: #323232 !important;
  fill: #323232 !important; }

.fc--gray-1-lighter {
  color: #565656 !important;
  fill: #565656 !important; }

.bgc--gray-1 {
  background-color: #444444 !important; }

.bgc--gray-1-darker {
  background-color: #323232 !important; }

.bgc--gray-1-lighter {
  background-color: #565656 !important; }

.fc--gray-2 {
  color: #717477 !important;
  fill: #717477 !important; }

.fc--gray-2-darker {
  color: #606265 !important;
  fill: #606265 !important; }

.fc--gray-2-lighter {
  color: #838689 !important;
  fill: #838689 !important; }

.bgc--gray-2 {
  background-color: #717477 !important; }

.bgc--gray-2-darker {
  background-color: #606265 !important; }

.bgc--gray-2-lighter {
  background-color: #838689 !important; }

.fc--gray-3 {
  color: #8a8d8f !important;
  fill: #8a8d8f !important; }

.fc--gray-3-darker {
  color: #787b7d !important;
  fill: #787b7d !important; }

.fc--gray-3-lighter {
  color: #9c9fa0 !important;
  fill: #9c9fa0 !important; }

.bgc--gray-3 {
  background-color: #8a8d8f !important; }

.bgc--gray-3-darker {
  background-color: #787b7d !important; }

.bgc--gray-3-lighter {
  background-color: #9c9fa0 !important; }

.fc--gray-4 {
  color: #97999b !important;
  fill: #97999b !important; }

.fc--gray-4-darker {
  color: #85878a !important;
  fill: #85878a !important; }

.fc--gray-4-lighter {
  color: #a9abad !important;
  fill: #a9abad !important; }

.bgc--gray-4 {
  background-color: #97999b !important; }

.bgc--gray-4-darker {
  background-color: #85878a !important; }

.bgc--gray-4-lighter {
  background-color: #a9abad !important; }

.fc--gray-5 {
  color: #a5a7a8 !important;
  fill: #a5a7a8 !important; }

.fc--gray-5-darker {
  color: #939596 !important;
  fill: #939596 !important; }

.fc--gray-5-lighter {
  color: #b7b9ba !important;
  fill: #b7b9ba !important; }

.bgc--gray-5 {
  background-color: #a5a7a8 !important; }

.bgc--gray-5-darker {
  background-color: #939596 !important; }

.bgc--gray-5-lighter {
  background-color: #b7b9ba !important; }

.fc--gray-6 {
  color: #b8b9bb !important;
  fill: #b8b9bb !important; }

.fc--gray-6-darker {
  color: #a6a7aa !important;
  fill: #a6a7aa !important; }

.fc--gray-6-lighter {
  color: #cacbcc !important;
  fill: #cacbcc !important; }

.bgc--gray-6 {
  background-color: #b8b9bb !important; }

.bgc--gray-6-darker {
  background-color: #a6a7aa !important; }

.bgc--gray-6-lighter {
  background-color: #cacbcc !important; }

.fc--gray-7 {
  color: #c6c7c9 !important;
  fill: #c6c7c9 !important; }

.fc--gray-7-darker {
  color: #b4b5b8 !important;
  fill: #b4b5b8 !important; }

.fc--gray-7-lighter {
  color: #d8d9da !important;
  fill: #d8d9da !important; }

.bgc--gray-7 {
  background-color: #c6c7c9 !important; }

.bgc--gray-7-darker {
  background-color: #b4b5b8 !important; }

.bgc--gray-7-lighter {
  background-color: #d8d9da !important; }

.fc--gray-8 {
  color: #d4d5d6 !important;
  fill: #d4d5d6 !important; }

.fc--gray-8-darker {
  color: #c2c3c5 !important;
  fill: #c2c3c5 !important; }

.fc--gray-8-lighter {
  color: #e6e7e7 !important;
  fill: #e6e7e7 !important; }

.bgc--gray-8 {
  background-color: #d4d5d6 !important; }

.bgc--gray-8-darker {
  background-color: #c2c3c5 !important; }

.bgc--gray-8-lighter {
  background-color: #e6e7e7 !important; }

.fc--gray-9 {
  color: #e3e3e4 !important;
  fill: #e3e3e4 !important; }

.fc--gray-9-darker {
  color: #d1d1d2 !important;
  fill: #d1d1d2 !important; }

.fc--gray-9-lighter {
  color: #f5f5f6 !important;
  fill: #f5f5f6 !important; }

.bgc--gray-9 {
  background-color: #e3e3e4 !important; }

.bgc--gray-9-darker {
  background-color: #d1d1d2 !important; }

.bgc--gray-9-lighter {
  background-color: #f5f5f6 !important; }

.fc--gray-10 {
  color: #f0f1f1 !important;
  fill: #f0f1f1 !important; }

.fc--gray-10-darker {
  color: #dee0e0 !important;
  fill: #dee0e0 !important; }

.fc--gray-10-lighter {
  color: white !important;
  fill: white !important; }

.bgc--gray-10 {
  background-color: #f0f1f1 !important; }

.bgc--gray-10-darker {
  background-color: #dee0e0 !important; }

.bgc--gray-10-lighter {
  background-color: white !important; }

.fc--error {
  color: #D8393C !important;
  fill: #D8393C !important; }

.fc--error-darker {
  color: #c6272a !important;
  fill: #c6272a !important; }

.fc--error-lighter {
  color: #de5759 !important;
  fill: #de5759 !important; }

.bgc--error {
  background-color: #D8393C !important; }

.bgc--error-darker {
  background-color: #c6272a !important; }

.bgc--error-lighter {
  background-color: #de5759 !important; }

.fc--error-fill {
  color: #FAE5E7 !important;
  fill: #FAE5E7 !important; }

.fc--error-fill-darker {
  color: #f4c7cb !important;
  fill: #f4c7cb !important; }

.fc--error-fill-lighter {
  color: white !important;
  fill: white !important; }

.bgc--error-fill {
  background-color: #FAE5E7 !important; }

.bgc--error-fill-darker {
  background-color: #f4c7cb !important; }

.bgc--error-fill-lighter {
  background-color: white !important; }

.fc--warning {
  color: #FF9904 !important;
  fill: #FF9904 !important; }

.fc--warning-darker {
  color: #df8500 !important;
  fill: #df8500 !important; }

.fc--warning-lighter {
  color: #ffa828 !important;
  fill: #ffa828 !important; }

.bgc--warning {
  background-color: #FF9904 !important; }

.bgc--warning-darker {
  background-color: #df8500 !important; }

.bgc--warning-lighter {
  background-color: #ffa828 !important; }

.fc--warning-fill {
  color: #fdedd6 !important;
  fill: #fdedd6 !important; }

.fc--warning-fill-darker {
  color: #fbdeb4 !important;
  fill: #fbdeb4 !important; }

.fc--warning-fill-lighter {
  color: #fffcf8 !important;
  fill: #fffcf8 !important; }

.bgc--warning-fill {
  background-color: #fdedd6 !important; }

.bgc--warning-fill-darker {
  background-color: #fbdeb4 !important; }

.bgc--warning-fill-lighter {
  background-color: #fffcf8 !important; }

.fc--success {
  color: #4a9864 !important;
  fill: #4a9864 !important; }

.fc--success-darker {
  color: #3e8054 !important;
  fill: #3e8054 !important; }

.fc--success-lighter {
  color: #58ae75 !important;
  fill: #58ae75 !important; }

.bgc--success {
  background-color: #4a9864 !important; }

.bgc--success-darker {
  background-color: #3e8054 !important; }

.bgc--success-lighter {
  background-color: #58ae75 !important; }

.fc--success-fill {
  color: #c4decd !important;
  fill: #c4decd !important; }

.fc--success-fill-darker {
  color: #add1ba !important;
  fill: #add1ba !important; }

.fc--success-fill-lighter {
  color: #dbebe0 !important;
  fill: #dbebe0 !important; }

.bgc--success-fill {
  background-color: #c4decd !important; }

.bgc--success-fill-darker {
  background-color: #add1ba !important; }

.bgc--success-fill-lighter {
  background-color: #dbebe0 !important; }

.fc--white {
  color: #ffffff !important;
  fill: #ffffff !important; }

.fc--white-darker {
  color: #ededed !important;
  fill: #ededed !important; }

.fc--white-lighter {
  color: white !important;
  fill: white !important; }

.bgc--white {
  background-color: #ffffff !important; }

.bgc--white-darker {
  background-color: #ededed !important; }

.bgc--white-lighter {
  background-color: white !important; }

/**
 *
 * TYPOGRAPHY: VARS
 *
 */
/**
 *
 * TYPOGRAPHY: FUNCTIONS
 *
 */
.table .table__inner {
  overflow: visible; }

.table .table__head {
  background: #137cbd;
  color: #ffffff;
  border-radius: 4px 4px 0 0;
  font-weight: 500;
  overflow: hidden; }
  .table .table__head .table__cell {
    padding-top: 11px;
    padding-bottom: 11px;
    position: relative; }
    .table .table__head .table__cell svg {
      display: block;
      width: 11px;
      height: 11px;
      fill: #ffffff;
      margin: 0 0 0 8px;
      opacity: 0; }
    .table .table__head .table__cell.is-sortable {
      cursor: pointer; }
      .table .table__head .table__cell.is-sortable:hover, .table .table__head .table__cell.is-sortable:hover:before {
        background: rgba(255, 255, 255, 0.05); }
    .table .table__head .table__cell:hover svg, .table .table__head .table__cell.is-sorted svg {
      opacity: 1; }
    .table .table__head .table__cell.is-sorted, .table .table__head .table__cell.is-sorted:before {
      background: rgba(12, 97, 150, 0.5); }
    .table .table__head .table__cell:first-child:before, .table .table__head .table__cell:last-child:before {
      content: "";
      display: block;
      width: 500px;
      height: 100%;
      background: #137cbd;
      position: absolute;
      top: 0; }
    .table .table__head .table__cell:first-child:before {
      right: 100%;
      left: auto; }
    .table .table__head .table__cell:last-child:before {
      left: 100%;
      right: auto; }

.table .table__body {
  margin: 0; }
  .table .table__body .table__row {
    background: #ffffff;
    transition: all 150ms; }
    .table .table__body .table__row:first-child {
      border-radius: 0; }
    .table .table__body .table__row:last-child {
      border-radius: 0 0 4px 4px; }
    .table .table__body .table__row:nth-child(even) {
      background: #fafafa; }
    .table .table__body .table__row:hover {
      background: #ededed; }
    .table .table__body .table__row.is-selected {
      background: #ebf6fc; }
  .table .table__body .table__cell {
    font-size: 12.9px; }

.table .table__row {
  display: flex;
  min-width: 768px;
  justify-content: flex-start;
  width: 100%;
  padding: 0 10px;
  border-bottom: 1px solid #f0f1f1; }
  .table .table__row:last-child {
    border-bottom: none; }
  .table .table__row.is-clickable {
    cursor: pointer; }
    .table .table__row.is-clickable:hover {
      background: #ededed; }

.table .table__cell {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  align-items: center;
  padding: 7px 10px 6px; }
  .table .table__cell.align-right {
    justify-content: flex-end; }

.table a, .table .table__link {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  background: none;
  color: #0C6196;
  border: none;
  font-size: inherit;
  font-family: inherit;
  text-align: left;
  text-decoration: underline;
  appearance: none;
  cursor: pointer; }

.table .status {
  margin: 0 8px 0 0; }

/*
 *
 * Media Queries
 *
 */
/*
 * Layout 03 - - - 0px - 1023px */
@media all and (max-width: 1023px) {
  .table .table__cell.size-medium-1 {
    width: 1%; }
  .table .table__cell.size-medium-2 {
    width: 2%; }
  .table .table__cell.size-medium-3 {
    width: 3%; }
  .table .table__cell.size-medium-4 {
    width: 4%; }
  .table .table__cell.size-medium-5 {
    width: 5%; }
  .table .table__cell.size-medium-6 {
    width: 6%; }
  .table .table__cell.size-medium-7 {
    width: 7%; }
  .table .table__cell.size-medium-8 {
    width: 8%; }
  .table .table__cell.size-medium-9 {
    width: 9%; }
  .table .table__cell.size-medium-10 {
    width: 10%; }
  .table .table__cell.size-medium-11 {
    width: 11%; }
  .table .table__cell.size-medium-12 {
    width: 12%; }
  .table .table__cell.size-medium-13 {
    width: 13%; }
  .table .table__cell.size-medium-14 {
    width: 14%; }
  .table .table__cell.size-medium-15 {
    width: 15%; }
  .table .table__cell.size-medium-16 {
    width: 16%; }
  .table .table__cell.size-medium-17 {
    width: 17%; }
  .table .table__cell.size-medium-18 {
    width: 18%; }
  .table .table__cell.size-medium-19 {
    width: 19%; }
  .table .table__cell.size-medium-20 {
    width: 20%; }
  .table .table__cell.size-medium-21 {
    width: 21%; }
  .table .table__cell.size-medium-22 {
    width: 22%; }
  .table .table__cell.size-medium-23 {
    width: 23%; }
  .table .table__cell.size-medium-24 {
    width: 24%; }
  .table .table__cell.size-medium-25 {
    width: 25%; }
  .table .table__cell.size-medium-26 {
    width: 26%; }
  .table .table__cell.size-medium-27 {
    width: 27%; }
  .table .table__cell.size-medium-28 {
    width: 28%; }
  .table .table__cell.size-medium-29 {
    width: 29%; }
  .table .table__cell.size-medium-30 {
    width: 30%; }
  .table .table__cell.size-medium-31 {
    width: 31%; }
  .table .table__cell.size-medium-32 {
    width: 32%; }
  .table .table__cell.size-medium-33 {
    width: 33%; }
  .table .table__cell.size-medium-34 {
    width: 34%; }
  .table .table__cell.size-medium-35 {
    width: 35%; }
  .table .table__cell.size-medium-36 {
    width: 36%; }
  .table .table__cell.size-medium-37 {
    width: 37%; }
  .table .table__cell.size-medium-38 {
    width: 38%; }
  .table .table__cell.size-medium-39 {
    width: 39%; }
  .table .table__cell.size-medium-40 {
    width: 40%; }
  .table .table__cell.size-medium-41 {
    width: 41%; }
  .table .table__cell.size-medium-42 {
    width: 42%; }
  .table .table__cell.size-medium-43 {
    width: 43%; }
  .table .table__cell.size-medium-44 {
    width: 44%; }
  .table .table__cell.size-medium-45 {
    width: 45%; }
  .table .table__cell.size-medium-46 {
    width: 46%; }
  .table .table__cell.size-medium-47 {
    width: 47%; }
  .table .table__cell.size-medium-48 {
    width: 48%; }
  .table .table__cell.size-medium-49 {
    width: 49%; }
  .table .table__cell.size-medium-50 {
    width: 50%; }
  .table .table__cell.size-medium-51 {
    width: 51%; }
  .table .table__cell.size-medium-52 {
    width: 52%; }
  .table .table__cell.size-medium-53 {
    width: 53%; }
  .table .table__cell.size-medium-54 {
    width: 54%; }
  .table .table__cell.size-medium-55 {
    width: 55%; }
  .table .table__cell.size-medium-56 {
    width: 56%; }
  .table .table__cell.size-medium-57 {
    width: 57%; }
  .table .table__cell.size-medium-58 {
    width: 58%; }
  .table .table__cell.size-medium-59 {
    width: 59%; }
  .table .table__cell.size-medium-60 {
    width: 60%; }
  .table .table__cell.size-medium-61 {
    width: 61%; }
  .table .table__cell.size-medium-62 {
    width: 62%; }
  .table .table__cell.size-medium-63 {
    width: 63%; }
  .table .table__cell.size-medium-64 {
    width: 64%; }
  .table .table__cell.size-medium-65 {
    width: 65%; }
  .table .table__cell.size-medium-66 {
    width: 66%; }
  .table .table__cell.size-medium-67 {
    width: 67%; }
  .table .table__cell.size-medium-68 {
    width: 68%; }
  .table .table__cell.size-medium-69 {
    width: 69%; }
  .table .table__cell.size-medium-70 {
    width: 70%; }
  .table .table__cell.size-medium-71 {
    width: 71%; }
  .table .table__cell.size-medium-72 {
    width: 72%; }
  .table .table__cell.size-medium-73 {
    width: 73%; }
  .table .table__cell.size-medium-74 {
    width: 74%; }
  .table .table__cell.size-medium-75 {
    width: 75%; }
  .table .table__cell.size-medium-76 {
    width: 76%; }
  .table .table__cell.size-medium-77 {
    width: 77%; }
  .table .table__cell.size-medium-78 {
    width: 78%; }
  .table .table__cell.size-medium-79 {
    width: 79%; }
  .table .table__cell.size-medium-80 {
    width: 80%; }
  .table .table__cell.size-medium-81 {
    width: 81%; }
  .table .table__cell.size-medium-82 {
    width: 82%; }
  .table .table__cell.size-medium-83 {
    width: 83%; }
  .table .table__cell.size-medium-84 {
    width: 84%; }
  .table .table__cell.size-medium-85 {
    width: 85%; }
  .table .table__cell.size-medium-86 {
    width: 86%; }
  .table .table__cell.size-medium-87 {
    width: 87%; }
  .table .table__cell.size-medium-88 {
    width: 88%; }
  .table .table__cell.size-medium-89 {
    width: 89%; }
  .table .table__cell.size-medium-90 {
    width: 90%; }
  .table .table__cell.size-medium-91 {
    width: 91%; }
  .table .table__cell.size-medium-92 {
    width: 92%; }
  .table .table__cell.size-medium-93 {
    width: 93%; }
  .table .table__cell.size-medium-94 {
    width: 94%; }
  .table .table__cell.size-medium-95 {
    width: 95%; }
  .table .table__cell.size-medium-96 {
    width: 96%; }
  .table .table__cell.size-medium-97 {
    width: 97%; }
  .table .table__cell.size-medium-98 {
    width: 98%; }
  .table .table__cell.size-medium-99 {
    width: 99%; }
  .table .table__cell.size-medium-100 {
    width: 100%; }
  .table .table__cell.hide-medium {
    display: none; } }

/*
 * Layout 03 - - - 1024px - 1179px */
@media all and (min-width: 1024px) {
  .table .table__cell.size-large-1 {
    width: 1%; }
  .table .table__cell.size-large-2 {
    width: 2%; }
  .table .table__cell.size-large-3 {
    width: 3%; }
  .table .table__cell.size-large-4 {
    width: 4%; }
  .table .table__cell.size-large-5 {
    width: 5%; }
  .table .table__cell.size-large-6 {
    width: 6%; }
  .table .table__cell.size-large-7 {
    width: 7%; }
  .table .table__cell.size-large-8 {
    width: 8%; }
  .table .table__cell.size-large-9 {
    width: 9%; }
  .table .table__cell.size-large-10 {
    width: 10%; }
  .table .table__cell.size-large-11 {
    width: 11%; }
  .table .table__cell.size-large-12 {
    width: 12%; }
  .table .table__cell.size-large-13 {
    width: 13%; }
  .table .table__cell.size-large-14 {
    width: 14%; }
  .table .table__cell.size-large-15 {
    width: 15%; }
  .table .table__cell.size-large-16 {
    width: 16%; }
  .table .table__cell.size-large-17 {
    width: 17%; }
  .table .table__cell.size-large-18 {
    width: 18%; }
  .table .table__cell.size-large-19 {
    width: 19%; }
  .table .table__cell.size-large-20 {
    width: 20%; }
  .table .table__cell.size-large-21 {
    width: 21%; }
  .table .table__cell.size-large-22 {
    width: 22%; }
  .table .table__cell.size-large-23 {
    width: 23%; }
  .table .table__cell.size-large-24 {
    width: 24%; }
  .table .table__cell.size-large-25 {
    width: 25%; }
  .table .table__cell.size-large-26 {
    width: 26%; }
  .table .table__cell.size-large-27 {
    width: 27%; }
  .table .table__cell.size-large-28 {
    width: 28%; }
  .table .table__cell.size-large-29 {
    width: 29%; }
  .table .table__cell.size-large-30 {
    width: 30%; }
  .table .table__cell.size-large-31 {
    width: 31%; }
  .table .table__cell.size-large-32 {
    width: 32%; }
  .table .table__cell.size-large-33 {
    width: 33%; }
  .table .table__cell.size-large-34 {
    width: 34%; }
  .table .table__cell.size-large-35 {
    width: 35%; }
  .table .table__cell.size-large-36 {
    width: 36%; }
  .table .table__cell.size-large-37 {
    width: 37%; }
  .table .table__cell.size-large-38 {
    width: 38%; }
  .table .table__cell.size-large-39 {
    width: 39%; }
  .table .table__cell.size-large-40 {
    width: 40%; }
  .table .table__cell.size-large-41 {
    width: 41%; }
  .table .table__cell.size-large-42 {
    width: 42%; }
  .table .table__cell.size-large-43 {
    width: 43%; }
  .table .table__cell.size-large-44 {
    width: 44%; }
  .table .table__cell.size-large-45 {
    width: 45%; }
  .table .table__cell.size-large-46 {
    width: 46%; }
  .table .table__cell.size-large-47 {
    width: 47%; }
  .table .table__cell.size-large-48 {
    width: 48%; }
  .table .table__cell.size-large-49 {
    width: 49%; }
  .table .table__cell.size-large-50 {
    width: 50%; }
  .table .table__cell.size-large-51 {
    width: 51%; }
  .table .table__cell.size-large-52 {
    width: 52%; }
  .table .table__cell.size-large-53 {
    width: 53%; }
  .table .table__cell.size-large-54 {
    width: 54%; }
  .table .table__cell.size-large-55 {
    width: 55%; }
  .table .table__cell.size-large-56 {
    width: 56%; }
  .table .table__cell.size-large-57 {
    width: 57%; }
  .table .table__cell.size-large-58 {
    width: 58%; }
  .table .table__cell.size-large-59 {
    width: 59%; }
  .table .table__cell.size-large-60 {
    width: 60%; }
  .table .table__cell.size-large-61 {
    width: 61%; }
  .table .table__cell.size-large-62 {
    width: 62%; }
  .table .table__cell.size-large-63 {
    width: 63%; }
  .table .table__cell.size-large-64 {
    width: 64%; }
  .table .table__cell.size-large-65 {
    width: 65%; }
  .table .table__cell.size-large-66 {
    width: 66%; }
  .table .table__cell.size-large-67 {
    width: 67%; }
  .table .table__cell.size-large-68 {
    width: 68%; }
  .table .table__cell.size-large-69 {
    width: 69%; }
  .table .table__cell.size-large-70 {
    width: 70%; }
  .table .table__cell.size-large-71 {
    width: 71%; }
  .table .table__cell.size-large-72 {
    width: 72%; }
  .table .table__cell.size-large-73 {
    width: 73%; }
  .table .table__cell.size-large-74 {
    width: 74%; }
  .table .table__cell.size-large-75 {
    width: 75%; }
  .table .table__cell.size-large-76 {
    width: 76%; }
  .table .table__cell.size-large-77 {
    width: 77%; }
  .table .table__cell.size-large-78 {
    width: 78%; }
  .table .table__cell.size-large-79 {
    width: 79%; }
  .table .table__cell.size-large-80 {
    width: 80%; }
  .table .table__cell.size-large-81 {
    width: 81%; }
  .table .table__cell.size-large-82 {
    width: 82%; }
  .table .table__cell.size-large-83 {
    width: 83%; }
  .table .table__cell.size-large-84 {
    width: 84%; }
  .table .table__cell.size-large-85 {
    width: 85%; }
  .table .table__cell.size-large-86 {
    width: 86%; }
  .table .table__cell.size-large-87 {
    width: 87%; }
  .table .table__cell.size-large-88 {
    width: 88%; }
  .table .table__cell.size-large-89 {
    width: 89%; }
  .table .table__cell.size-large-90 {
    width: 90%; }
  .table .table__cell.size-large-91 {
    width: 91%; }
  .table .table__cell.size-large-92 {
    width: 92%; }
  .table .table__cell.size-large-93 {
    width: 93%; }
  .table .table__cell.size-large-94 {
    width: 94%; }
  .table .table__cell.size-large-95 {
    width: 95%; }
  .table .table__cell.size-large-96 {
    width: 96%; }
  .table .table__cell.size-large-97 {
    width: 97%; }
  .table .table__cell.size-large-98 {
    width: 98%; }
  .table .table__cell.size-large-99 {
    width: 99%; }
  .table .table__cell.size-large-100 {
    width: 100%; }
  .table .table__cell.hide-large {
    display: none; } }
