/**
 *
 * BOXING: VARS
 *
 */
/**
 *
 * BOXING: FUNCTIONS
 *
 */
/**
 *
 * BOXING: MIXINS
 *
 */
/**
 *
 * COLORS: VARS
 *
 */
/**
 *
 * COLORS: FUNCTIONS
 *
 */
/**
 *
 * COLORS: VARS
 *
 */
/**
 *
 * COLORS: FUNCTIONS
 *
 */
/**
 *
 * COLORS: INLINES
 *
 */
.fc--primary-1 {
  color: #0C6196 !important;
  fill: #0C6196 !important; }

.fc--primary-1-darker {
  color: #094c75 !important;
  fill: #094c75 !important; }

.fc--primary-1-lighter {
  color: #0f76b7 !important;
  fill: #0f76b7 !important; }

.bgc--primary-1 {
  background-color: #0C6196 !important; }

.bgc--primary-1-darker {
  background-color: #094c75 !important; }

.bgc--primary-1-lighter {
  background-color: #0f76b7 !important; }

.fc--primary-1-light {
  color: #137cbd !important;
  fill: #137cbd !important; }

.fc--primary-1-light-darker {
  color: #10679d !important;
  fill: #10679d !important; }

.fc--primary-1-light-lighter {
  color: #1691dd !important;
  fill: #1691dd !important; }

.bgc--primary-1-light {
  background-color: #137cbd !important; }

.bgc--primary-1-light-darker {
  background-color: #10679d !important; }

.bgc--primary-1-light-lighter {
  background-color: #1691dd !important; }

.fc--secondary-1 {
  color: #99BACB !important;
  fill: #99BACB !important; }

.fc--secondary-1-darker {
  color: #81aabf !important;
  fill: #81aabf !important; }

.fc--secondary-1-lighter {
  color: #b1cad7 !important;
  fill: #b1cad7 !important; }

.bgc--secondary-1 {
  background-color: #99BACB !important; }

.bgc--secondary-1-darker {
  background-color: #81aabf !important; }

.bgc--secondary-1-lighter {
  background-color: #b1cad7 !important; }

.fc--secondary-1-light {
  color: #F8FCFE !important;
  fill: #F8FCFE !important; }

.fc--secondary-1-light-darker {
  color: #d9effa !important;
  fill: #d9effa !important; }

.fc--secondary-1-light-lighter {
  color: white !important;
  fill: white !important; }

.bgc--secondary-1-light {
  background-color: #F8FCFE !important; }

.bgc--secondary-1-light-darker {
  background-color: #d9effa !important; }

.bgc--secondary-1-light-lighter {
  background-color: white !important; }

.fc--secondary-2 {
  color: #31a175 !important;
  fill: #31a175 !important; }

.fc--secondary-2-darker {
  color: #298661 !important;
  fill: #298661 !important; }

.fc--secondary-2-lighter {
  color: #39bc89 !important;
  fill: #39bc89 !important; }

.bgc--secondary-2 {
  background-color: #31a175 !important; }

.bgc--secondary-2-darker {
  background-color: #298661 !important; }

.bgc--secondary-2-lighter {
  background-color: #39bc89 !important; }

.fc--secondary-2-light {
  color: #42ad83 !important;
  fill: #42ad83 !important; }

.fc--secondary-2-light-darker {
  color: #38936f !important;
  fill: #38936f !important; }

.fc--secondary-2-light-lighter {
  color: #55be95 !important;
  fill: #55be95 !important; }

.bgc--secondary-2-light {
  background-color: #42ad83 !important; }

.bgc--secondary-2-light-darker {
  background-color: #38936f !important; }

.bgc--secondary-2-light-lighter {
  background-color: #55be95 !important; }

.fc--gray-1 {
  color: #444444 !important;
  fill: #444444 !important; }

.fc--gray-1-darker {
  color: #323232 !important;
  fill: #323232 !important; }

.fc--gray-1-lighter {
  color: #565656 !important;
  fill: #565656 !important; }

.bgc--gray-1 {
  background-color: #444444 !important; }

.bgc--gray-1-darker {
  background-color: #323232 !important; }

.bgc--gray-1-lighter {
  background-color: #565656 !important; }

.fc--gray-2 {
  color: #717477 !important;
  fill: #717477 !important; }

.fc--gray-2-darker {
  color: #606265 !important;
  fill: #606265 !important; }

.fc--gray-2-lighter {
  color: #838689 !important;
  fill: #838689 !important; }

.bgc--gray-2 {
  background-color: #717477 !important; }

.bgc--gray-2-darker {
  background-color: #606265 !important; }

.bgc--gray-2-lighter {
  background-color: #838689 !important; }

.fc--gray-3 {
  color: #8a8d8f !important;
  fill: #8a8d8f !important; }

.fc--gray-3-darker {
  color: #787b7d !important;
  fill: #787b7d !important; }

.fc--gray-3-lighter {
  color: #9c9fa0 !important;
  fill: #9c9fa0 !important; }

.bgc--gray-3 {
  background-color: #8a8d8f !important; }

.bgc--gray-3-darker {
  background-color: #787b7d !important; }

.bgc--gray-3-lighter {
  background-color: #9c9fa0 !important; }

.fc--gray-4 {
  color: #97999b !important;
  fill: #97999b !important; }

.fc--gray-4-darker {
  color: #85878a !important;
  fill: #85878a !important; }

.fc--gray-4-lighter {
  color: #a9abad !important;
  fill: #a9abad !important; }

.bgc--gray-4 {
  background-color: #97999b !important; }

.bgc--gray-4-darker {
  background-color: #85878a !important; }

.bgc--gray-4-lighter {
  background-color: #a9abad !important; }

.fc--gray-5 {
  color: #a5a7a8 !important;
  fill: #a5a7a8 !important; }

.fc--gray-5-darker {
  color: #939596 !important;
  fill: #939596 !important; }

.fc--gray-5-lighter {
  color: #b7b9ba !important;
  fill: #b7b9ba !important; }

.bgc--gray-5 {
  background-color: #a5a7a8 !important; }

.bgc--gray-5-darker {
  background-color: #939596 !important; }

.bgc--gray-5-lighter {
  background-color: #b7b9ba !important; }

.fc--gray-6 {
  color: #b8b9bb !important;
  fill: #b8b9bb !important; }

.fc--gray-6-darker {
  color: #a6a7aa !important;
  fill: #a6a7aa !important; }

.fc--gray-6-lighter {
  color: #cacbcc !important;
  fill: #cacbcc !important; }

.bgc--gray-6 {
  background-color: #b8b9bb !important; }

.bgc--gray-6-darker {
  background-color: #a6a7aa !important; }

.bgc--gray-6-lighter {
  background-color: #cacbcc !important; }

.fc--gray-7 {
  color: #c6c7c9 !important;
  fill: #c6c7c9 !important; }

.fc--gray-7-darker {
  color: #b4b5b8 !important;
  fill: #b4b5b8 !important; }

.fc--gray-7-lighter {
  color: #d8d9da !important;
  fill: #d8d9da !important; }

.bgc--gray-7 {
  background-color: #c6c7c9 !important; }

.bgc--gray-7-darker {
  background-color: #b4b5b8 !important; }

.bgc--gray-7-lighter {
  background-color: #d8d9da !important; }

.fc--gray-8 {
  color: #d4d5d6 !important;
  fill: #d4d5d6 !important; }

.fc--gray-8-darker {
  color: #c2c3c5 !important;
  fill: #c2c3c5 !important; }

.fc--gray-8-lighter {
  color: #e6e7e7 !important;
  fill: #e6e7e7 !important; }

.bgc--gray-8 {
  background-color: #d4d5d6 !important; }

.bgc--gray-8-darker {
  background-color: #c2c3c5 !important; }

.bgc--gray-8-lighter {
  background-color: #e6e7e7 !important; }

.fc--gray-9 {
  color: #e3e3e4 !important;
  fill: #e3e3e4 !important; }

.fc--gray-9-darker {
  color: #d1d1d2 !important;
  fill: #d1d1d2 !important; }

.fc--gray-9-lighter {
  color: #f5f5f6 !important;
  fill: #f5f5f6 !important; }

.bgc--gray-9 {
  background-color: #e3e3e4 !important; }

.bgc--gray-9-darker {
  background-color: #d1d1d2 !important; }

.bgc--gray-9-lighter {
  background-color: #f5f5f6 !important; }

.fc--gray-10 {
  color: #f0f1f1 !important;
  fill: #f0f1f1 !important; }

.fc--gray-10-darker {
  color: #dee0e0 !important;
  fill: #dee0e0 !important; }

.fc--gray-10-lighter {
  color: white !important;
  fill: white !important; }

.bgc--gray-10 {
  background-color: #f0f1f1 !important; }

.bgc--gray-10-darker {
  background-color: #dee0e0 !important; }

.bgc--gray-10-lighter {
  background-color: white !important; }

.fc--error {
  color: #D8393C !important;
  fill: #D8393C !important; }

.fc--error-darker {
  color: #c6272a !important;
  fill: #c6272a !important; }

.fc--error-lighter {
  color: #de5759 !important;
  fill: #de5759 !important; }

.bgc--error {
  background-color: #D8393C !important; }

.bgc--error-darker {
  background-color: #c6272a !important; }

.bgc--error-lighter {
  background-color: #de5759 !important; }

.fc--error-fill {
  color: #FAE5E7 !important;
  fill: #FAE5E7 !important; }

.fc--error-fill-darker {
  color: #f4c7cb !important;
  fill: #f4c7cb !important; }

.fc--error-fill-lighter {
  color: white !important;
  fill: white !important; }

.bgc--error-fill {
  background-color: #FAE5E7 !important; }

.bgc--error-fill-darker {
  background-color: #f4c7cb !important; }

.bgc--error-fill-lighter {
  background-color: white !important; }

.fc--warning {
  color: #FF9904 !important;
  fill: #FF9904 !important; }

.fc--warning-darker {
  color: #df8500 !important;
  fill: #df8500 !important; }

.fc--warning-lighter {
  color: #ffa828 !important;
  fill: #ffa828 !important; }

.bgc--warning {
  background-color: #FF9904 !important; }

.bgc--warning-darker {
  background-color: #df8500 !important; }

.bgc--warning-lighter {
  background-color: #ffa828 !important; }

.fc--warning-fill {
  color: #fdedd6 !important;
  fill: #fdedd6 !important; }

.fc--warning-fill-darker {
  color: #fbdeb4 !important;
  fill: #fbdeb4 !important; }

.fc--warning-fill-lighter {
  color: #fffcf8 !important;
  fill: #fffcf8 !important; }

.bgc--warning-fill {
  background-color: #fdedd6 !important; }

.bgc--warning-fill-darker {
  background-color: #fbdeb4 !important; }

.bgc--warning-fill-lighter {
  background-color: #fffcf8 !important; }

.fc--success {
  color: #4a9864 !important;
  fill: #4a9864 !important; }

.fc--success-darker {
  color: #3e8054 !important;
  fill: #3e8054 !important; }

.fc--success-lighter {
  color: #58ae75 !important;
  fill: #58ae75 !important; }

.bgc--success {
  background-color: #4a9864 !important; }

.bgc--success-darker {
  background-color: #3e8054 !important; }

.bgc--success-lighter {
  background-color: #58ae75 !important; }

.fc--success-fill {
  color: #c4decd !important;
  fill: #c4decd !important; }

.fc--success-fill-darker {
  color: #add1ba !important;
  fill: #add1ba !important; }

.fc--success-fill-lighter {
  color: #dbebe0 !important;
  fill: #dbebe0 !important; }

.bgc--success-fill {
  background-color: #c4decd !important; }

.bgc--success-fill-darker {
  background-color: #add1ba !important; }

.bgc--success-fill-lighter {
  background-color: #dbebe0 !important; }

.fc--white {
  color: #ffffff !important;
  fill: #ffffff !important; }

.fc--white-darker {
  color: #ededed !important;
  fill: #ededed !important; }

.fc--white-lighter {
  color: white !important;
  fill: white !important; }

.bgc--white {
  background-color: #ffffff !important; }

.bgc--white-darker {
  background-color: #ededed !important; }

.bgc--white-lighter {
  background-color: white !important; }

/**
 *
 * TYPOGRAPHY: VARS
 *
 */
/**
 *
 * TYPOGRAPHY: FUNCTIONS
 *
 */
/*
 *
 * Document */
.document {
  width: 100%;
  position: relative; }
  .document .document__visual {
    width: 100%;
    position: relative;
    z-index: 1; }
    .document .document__visual img {
      display: block;
      width: 100%; }

/*
 *
 * Label */
.annotation {
  position: absolute;
  z-index: 2;
  margin-top: 1px;
  min-height: 12px;
  user-select: none;
  /*
     *
     * Define variations */
  /*
     *
     * Define states */
  /*
     *
     * Color variations */ }
  .annotation .annotation__button {
    display: block;
    position: absolute;
    left: -3px;
    right: -3px;
    top: -4px;
    bottom: -4px;
    z-index: 2; }
    .annotation .annotation__button button {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      padding: 0;
      cursor: pointer;
      transition: all 150ms;
      background: none;
      border: none;
      appearance: none;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0; }
      .annotation .annotation__button button:hover {
        background: rgba(0, 0, 0, 0.2); }
  .annotation .annotation__label {
    display: flex;
    align-items: center;
    position: absolute;
    min-height: 20px;
    left: 100%;
    top: 50%;
    transform: translate(0px, -50%) scale3d(0, 1, 1);
    background: #42ad83;
    color: #ffffff;
    font-size: 9px;
    line-height: 1.1;
    opacity: 1;
    transition: all 150ms;
    transform-origin: 0% 50%;
    padding: 0 0 0 8px;
    border-radius: 0 4px 4px 0; }
    .annotation .annotation__label button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      overflow: hidden;
      padding: 0;
      border-radius: 4px;
      background: none;
      border: none;
      margin: 0;
      cursor: pointer;
      appearance: none;
      color: #ffffff; }
      .annotation .annotation__label button i {
        font-size: 14px; }
      .annotation .annotation__label button:hover {
        background: rgba(0, 0, 0, 0.25); }
    .annotation .annotation__label span {
      display: block;
      padding: 0 4px 0 0; }
  .annotation .annotation__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -3px;
    top: 50%;
    z-index: 3;
    background: #4a9864;
    color: #ffffff;
    border-radius: 50%;
    transform: translate(100%, -50%); }
    .annotation .annotation__icon i {
      font-size: 11px; }
  .annotation.is-annotated .annotation__button button {
    border: 1px solid #4a9864;
    background: rgba(74, 152, 100, 0.2); }
    .annotation.is-annotated .annotation__button button:hover {
      background: rgba(74, 152, 100, 0.3); }
  .annotation.is-annotated.color-warning .annotation__button button {
    border: 1px solid #FF9904;
    background: rgba(255, 153, 4, 0.2); }
    .annotation.is-annotated.color-warning .annotation__button button:hover {
      background: rgba(255, 153, 4, 0.3); }
  .annotation.is-annotated.color-warning .annotation__icon {
    background: #FF9904; }
  .annotation.is-annotated.color-warning .annotation__label {
    background: #ffae37; }
  .annotation:hover {
    z-index: 100; }
    .annotation:hover .annotation__label {
      opacity: 1;
      transform: translate(2px, -50%) scale3d(1, 1, 1); }
  .annotation.has-icon .annotation__button {
    right: -13px; }
  .annotation.has-icon .annotation__label {
    padding: 0 0 0 18px;
    transform: translate(10px, -50%) scale3d(0, 1, 1); }
  .annotation.has-icon:hover .annotation__label {
    transform: translate(10px, -50%) scale3d(1, 1, 1); }

/*
 *
 * Progressbar */
.progressbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  line-height: 1.1;
  color: #97999b;
  /*
     *
     * Define variation */ }
  .progressbar .progressbar__bar {
    width: 100%;
    height: 4px;
    background: #f0f1f1;
    border-radius: 20px;
    overflow: hidden;
    position: relative; }
    .progressbar .progressbar__bar:after {
      content: "";
      display: block;
      width: 75%;
      height: 100%;
      background: #4a9864;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 20px;
      transition: all 150ms; }
  .progressbar .progressbar__label {
    min-width: 32px;
    flex-shrink: 0;
    flex-grow: 0;
    text-align: right; }
  .progressbar[data-progress="0"] .progressbar__bar:after {
    width: 0%; }
  .progressbar[data-progress="1"] .progressbar__bar:after {
    width: 1%; }
  .progressbar[data-progress="2"] .progressbar__bar:after {
    width: 2%; }
  .progressbar[data-progress="3"] .progressbar__bar:after {
    width: 3%; }
  .progressbar[data-progress="4"] .progressbar__bar:after {
    width: 4%; }
  .progressbar[data-progress="5"] .progressbar__bar:after {
    width: 5%; }
  .progressbar[data-progress="6"] .progressbar__bar:after {
    width: 6%; }
  .progressbar[data-progress="7"] .progressbar__bar:after {
    width: 7%; }
  .progressbar[data-progress="8"] .progressbar__bar:after {
    width: 8%; }
  .progressbar[data-progress="9"] .progressbar__bar:after {
    width: 9%; }
  .progressbar[data-progress="10"] .progressbar__bar:after {
    width: 10%; }
  .progressbar[data-progress="11"] .progressbar__bar:after {
    width: 11%; }
  .progressbar[data-progress="12"] .progressbar__bar:after {
    width: 12%; }
  .progressbar[data-progress="13"] .progressbar__bar:after {
    width: 13%; }
  .progressbar[data-progress="14"] .progressbar__bar:after {
    width: 14%; }
  .progressbar[data-progress="15"] .progressbar__bar:after {
    width: 15%; }
  .progressbar[data-progress="16"] .progressbar__bar:after {
    width: 16%; }
  .progressbar[data-progress="17"] .progressbar__bar:after {
    width: 17%; }
  .progressbar[data-progress="18"] .progressbar__bar:after {
    width: 18%; }
  .progressbar[data-progress="19"] .progressbar__bar:after {
    width: 19%; }
  .progressbar[data-progress="20"] .progressbar__bar:after {
    width: 20%; }
  .progressbar[data-progress="21"] .progressbar__bar:after {
    width: 21%; }
  .progressbar[data-progress="22"] .progressbar__bar:after {
    width: 22%; }
  .progressbar[data-progress="23"] .progressbar__bar:after {
    width: 23%; }
  .progressbar[data-progress="24"] .progressbar__bar:after {
    width: 24%; }
  .progressbar[data-progress="25"] .progressbar__bar:after {
    width: 25%; }
  .progressbar[data-progress="26"] .progressbar__bar:after {
    width: 26%; }
  .progressbar[data-progress="27"] .progressbar__bar:after {
    width: 27%; }
  .progressbar[data-progress="28"] .progressbar__bar:after {
    width: 28%; }
  .progressbar[data-progress="29"] .progressbar__bar:after {
    width: 29%; }
  .progressbar[data-progress="30"] .progressbar__bar:after {
    width: 30%; }
  .progressbar[data-progress="31"] .progressbar__bar:after {
    width: 31%; }
  .progressbar[data-progress="32"] .progressbar__bar:after {
    width: 32%; }
  .progressbar[data-progress="33"] .progressbar__bar:after {
    width: 33%; }
  .progressbar[data-progress="34"] .progressbar__bar:after {
    width: 34%; }
  .progressbar[data-progress="35"] .progressbar__bar:after {
    width: 35%; }
  .progressbar[data-progress="36"] .progressbar__bar:after {
    width: 36%; }
  .progressbar[data-progress="37"] .progressbar__bar:after {
    width: 37%; }
  .progressbar[data-progress="38"] .progressbar__bar:after {
    width: 38%; }
  .progressbar[data-progress="39"] .progressbar__bar:after {
    width: 39%; }
  .progressbar[data-progress="40"] .progressbar__bar:after {
    width: 40%; }
  .progressbar[data-progress="41"] .progressbar__bar:after {
    width: 41%; }
  .progressbar[data-progress="42"] .progressbar__bar:after {
    width: 42%; }
  .progressbar[data-progress="43"] .progressbar__bar:after {
    width: 43%; }
  .progressbar[data-progress="44"] .progressbar__bar:after {
    width: 44%; }
  .progressbar[data-progress="45"] .progressbar__bar:after {
    width: 45%; }
  .progressbar[data-progress="46"] .progressbar__bar:after {
    width: 46%; }
  .progressbar[data-progress="47"] .progressbar__bar:after {
    width: 47%; }
  .progressbar[data-progress="48"] .progressbar__bar:after {
    width: 48%; }
  .progressbar[data-progress="49"] .progressbar__bar:after {
    width: 49%; }
  .progressbar[data-progress="50"] .progressbar__bar:after {
    width: 50%; }
  .progressbar[data-progress="51"] .progressbar__bar:after {
    width: 51%; }
  .progressbar[data-progress="52"] .progressbar__bar:after {
    width: 52%; }
  .progressbar[data-progress="53"] .progressbar__bar:after {
    width: 53%; }
  .progressbar[data-progress="54"] .progressbar__bar:after {
    width: 54%; }
  .progressbar[data-progress="55"] .progressbar__bar:after {
    width: 55%; }
  .progressbar[data-progress="56"] .progressbar__bar:after {
    width: 56%; }
  .progressbar[data-progress="57"] .progressbar__bar:after {
    width: 57%; }
  .progressbar[data-progress="58"] .progressbar__bar:after {
    width: 58%; }
  .progressbar[data-progress="59"] .progressbar__bar:after {
    width: 59%; }
  .progressbar[data-progress="60"] .progressbar__bar:after {
    width: 60%; }
  .progressbar[data-progress="61"] .progressbar__bar:after {
    width: 61%; }
  .progressbar[data-progress="62"] .progressbar__bar:after {
    width: 62%; }
  .progressbar[data-progress="63"] .progressbar__bar:after {
    width: 63%; }
  .progressbar[data-progress="64"] .progressbar__bar:after {
    width: 64%; }
  .progressbar[data-progress="65"] .progressbar__bar:after {
    width: 65%; }
  .progressbar[data-progress="66"] .progressbar__bar:after {
    width: 66%; }
  .progressbar[data-progress="67"] .progressbar__bar:after {
    width: 67%; }
  .progressbar[data-progress="68"] .progressbar__bar:after {
    width: 68%; }
  .progressbar[data-progress="69"] .progressbar__bar:after {
    width: 69%; }
  .progressbar[data-progress="70"] .progressbar__bar:after {
    width: 70%; }
  .progressbar[data-progress="71"] .progressbar__bar:after {
    width: 71%; }
  .progressbar[data-progress="72"] .progressbar__bar:after {
    width: 72%; }
  .progressbar[data-progress="73"] .progressbar__bar:after {
    width: 73%; }
  .progressbar[data-progress="74"] .progressbar__bar:after {
    width: 74%; }
  .progressbar[data-progress="75"] .progressbar__bar:after {
    width: 75%; }
  .progressbar[data-progress="76"] .progressbar__bar:after {
    width: 76%; }
  .progressbar[data-progress="77"] .progressbar__bar:after {
    width: 77%; }
  .progressbar[data-progress="78"] .progressbar__bar:after {
    width: 78%; }
  .progressbar[data-progress="79"] .progressbar__bar:after {
    width: 79%; }
  .progressbar[data-progress="80"] .progressbar__bar:after {
    width: 80%; }
  .progressbar[data-progress="81"] .progressbar__bar:after {
    width: 81%; }
  .progressbar[data-progress="82"] .progressbar__bar:after {
    width: 82%; }
  .progressbar[data-progress="83"] .progressbar__bar:after {
    width: 83%; }
  .progressbar[data-progress="84"] .progressbar__bar:after {
    width: 84%; }
  .progressbar[data-progress="85"] .progressbar__bar:after {
    width: 85%; }
  .progressbar[data-progress="86"] .progressbar__bar:after {
    width: 86%; }
  .progressbar[data-progress="87"] .progressbar__bar:after {
    width: 87%; }
  .progressbar[data-progress="88"] .progressbar__bar:after {
    width: 88%; }
  .progressbar[data-progress="89"] .progressbar__bar:after {
    width: 89%; }
  .progressbar[data-progress="90"] .progressbar__bar:after {
    width: 90%; }
  .progressbar[data-progress="91"] .progressbar__bar:after {
    width: 91%; }
  .progressbar[data-progress="92"] .progressbar__bar:after {
    width: 92%; }
  .progressbar[data-progress="93"] .progressbar__bar:after {
    width: 93%; }
  .progressbar[data-progress="94"] .progressbar__bar:after {
    width: 94%; }
  .progressbar[data-progress="95"] .progressbar__bar:after {
    width: 95%; }
  .progressbar[data-progress="96"] .progressbar__bar:after {
    width: 96%; }
  .progressbar[data-progress="97"] .progressbar__bar:after {
    width: 97%; }
  .progressbar[data-progress="98"] .progressbar__bar:after {
    width: 98%; }
  .progressbar[data-progress="99"] .progressbar__bar:after {
    width: 99%; }
  .progressbar[data-progress="100"] .progressbar__bar:after {
    width: 100%; }
  .progressbar.type-almost .progressbar__bar:after {
    background: #0C6196; }
  .progressbar.type-warning .progressbar__bar:after {
    background: #FF9904; }
  .progressbar.type-error .progressbar__bar:after {
    background: #D8393C; }

/*
 *
 * Annotation fields */
.annFields {
  width: 100%;
  margin: 15px 0; }
  .annFields:first-child {
    margin-top: 0; }
  .annFields:last-child {
    margin-bottom: 0; }
  .annFields .annFields__list {
    width: 100%; }
  .annFields .annFields__item {
    width: 100%;
    margin: 5px 0 0 0; }
    .annFields .annFields__item:first-child {
      margin-top: 0; }
  .annFields .annFields__actions {
    width: 100%;
    margin: 10px 0 0 0; }

.annField {
  width: 100%;
  padding: 20px;
  position: relative;
  background: rgba(74, 152, 100, 0.15);
  border-radius: 4px;
  overflow: hidden;
  /*
    *
    * Define variations */ }
  .annField:before {
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    background: #4a9864;
    position: absolute;
    left: 0;
    top: 0; }
  .annField .field__label {
    color: #4a9864; }
  .annField.type-warning {
    background: rgba(255, 153, 4, 0.15); }
    .annField.type-warning:before {
      background: #FF9904; }
    .annField.type-warning .field__label {
      color: #FF9904; }
