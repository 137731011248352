/**
 *
 * TYPOGRAPHY: GENERAL
 *
 */

html {
	font-size: 16px; -webkit-text-size-adjust: 100%;
}

body {
	font-family: font(main); font-size: 14px; line-height: 1.8; font-weight: normal; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; text-rendering: optimizeLegibility; -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a {

	&.reset-default { text-decoration: none; }

}