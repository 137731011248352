/**
 *
 * BOXING: VARS
 *
 */
/**
 *
 * BOXING: FUNCTIONS
 *
 */
/**
 *
 * BOXING: MIXINS
 *
 */
/**
 *
 * COLORS: VARS
 *
 */
/**
 *
 * COLORS: FUNCTIONS
 *
 */
/**
 *
 * COLORS: VARS
 *
 */
/**
 *
 * COLORS: FUNCTIONS
 *
 */
/**
 *
 * COLORS: INLINES
 *
 */
.fc--primary-1 {
  color: #0C6196 !important;
  fill: #0C6196 !important; }

.fc--primary-1-darker {
  color: #094c75 !important;
  fill: #094c75 !important; }

.fc--primary-1-lighter {
  color: #0f76b7 !important;
  fill: #0f76b7 !important; }

.bgc--primary-1 {
  background-color: #0C6196 !important; }

.bgc--primary-1-darker {
  background-color: #094c75 !important; }

.bgc--primary-1-lighter {
  background-color: #0f76b7 !important; }

.fc--primary-1-light {
  color: #137cbd !important;
  fill: #137cbd !important; }

.fc--primary-1-light-darker {
  color: #10679d !important;
  fill: #10679d !important; }

.fc--primary-1-light-lighter {
  color: #1691dd !important;
  fill: #1691dd !important; }

.bgc--primary-1-light {
  background-color: #137cbd !important; }

.bgc--primary-1-light-darker {
  background-color: #10679d !important; }

.bgc--primary-1-light-lighter {
  background-color: #1691dd !important; }

.fc--secondary-1 {
  color: #99BACB !important;
  fill: #99BACB !important; }

.fc--secondary-1-darker {
  color: #81aabf !important;
  fill: #81aabf !important; }

.fc--secondary-1-lighter {
  color: #b1cad7 !important;
  fill: #b1cad7 !important; }

.bgc--secondary-1 {
  background-color: #99BACB !important; }

.bgc--secondary-1-darker {
  background-color: #81aabf !important; }

.bgc--secondary-1-lighter {
  background-color: #b1cad7 !important; }

.fc--secondary-1-light {
  color: #F8FCFE !important;
  fill: #F8FCFE !important; }

.fc--secondary-1-light-darker {
  color: #d9effa !important;
  fill: #d9effa !important; }

.fc--secondary-1-light-lighter {
  color: white !important;
  fill: white !important; }

.bgc--secondary-1-light {
  background-color: #F8FCFE !important; }

.bgc--secondary-1-light-darker {
  background-color: #d9effa !important; }

.bgc--secondary-1-light-lighter {
  background-color: white !important; }

.fc--secondary-2 {
  color: #31a175 !important;
  fill: #31a175 !important; }

.fc--secondary-2-darker {
  color: #298661 !important;
  fill: #298661 !important; }

.fc--secondary-2-lighter {
  color: #39bc89 !important;
  fill: #39bc89 !important; }

.bgc--secondary-2 {
  background-color: #31a175 !important; }

.bgc--secondary-2-darker {
  background-color: #298661 !important; }

.bgc--secondary-2-lighter {
  background-color: #39bc89 !important; }

.fc--secondary-2-light {
  color: #42ad83 !important;
  fill: #42ad83 !important; }

.fc--secondary-2-light-darker {
  color: #38936f !important;
  fill: #38936f !important; }

.fc--secondary-2-light-lighter {
  color: #55be95 !important;
  fill: #55be95 !important; }

.bgc--secondary-2-light {
  background-color: #42ad83 !important; }

.bgc--secondary-2-light-darker {
  background-color: #38936f !important; }

.bgc--secondary-2-light-lighter {
  background-color: #55be95 !important; }

.fc--gray-1 {
  color: #444444 !important;
  fill: #444444 !important; }

.fc--gray-1-darker {
  color: #323232 !important;
  fill: #323232 !important; }

.fc--gray-1-lighter {
  color: #565656 !important;
  fill: #565656 !important; }

.bgc--gray-1 {
  background-color: #444444 !important; }

.bgc--gray-1-darker {
  background-color: #323232 !important; }

.bgc--gray-1-lighter {
  background-color: #565656 !important; }

.fc--gray-2 {
  color: #717477 !important;
  fill: #717477 !important; }

.fc--gray-2-darker {
  color: #606265 !important;
  fill: #606265 !important; }

.fc--gray-2-lighter {
  color: #838689 !important;
  fill: #838689 !important; }

.bgc--gray-2 {
  background-color: #717477 !important; }

.bgc--gray-2-darker {
  background-color: #606265 !important; }

.bgc--gray-2-lighter {
  background-color: #838689 !important; }

.fc--gray-3 {
  color: #8a8d8f !important;
  fill: #8a8d8f !important; }

.fc--gray-3-darker {
  color: #787b7d !important;
  fill: #787b7d !important; }

.fc--gray-3-lighter {
  color: #9c9fa0 !important;
  fill: #9c9fa0 !important; }

.bgc--gray-3 {
  background-color: #8a8d8f !important; }

.bgc--gray-3-darker {
  background-color: #787b7d !important; }

.bgc--gray-3-lighter {
  background-color: #9c9fa0 !important; }

.fc--gray-4 {
  color: #97999b !important;
  fill: #97999b !important; }

.fc--gray-4-darker {
  color: #85878a !important;
  fill: #85878a !important; }

.fc--gray-4-lighter {
  color: #a9abad !important;
  fill: #a9abad !important; }

.bgc--gray-4 {
  background-color: #97999b !important; }

.bgc--gray-4-darker {
  background-color: #85878a !important; }

.bgc--gray-4-lighter {
  background-color: #a9abad !important; }

.fc--gray-5 {
  color: #a5a7a8 !important;
  fill: #a5a7a8 !important; }

.fc--gray-5-darker {
  color: #939596 !important;
  fill: #939596 !important; }

.fc--gray-5-lighter {
  color: #b7b9ba !important;
  fill: #b7b9ba !important; }

.bgc--gray-5 {
  background-color: #a5a7a8 !important; }

.bgc--gray-5-darker {
  background-color: #939596 !important; }

.bgc--gray-5-lighter {
  background-color: #b7b9ba !important; }

.fc--gray-6 {
  color: #b8b9bb !important;
  fill: #b8b9bb !important; }

.fc--gray-6-darker {
  color: #a6a7aa !important;
  fill: #a6a7aa !important; }

.fc--gray-6-lighter {
  color: #cacbcc !important;
  fill: #cacbcc !important; }

.bgc--gray-6 {
  background-color: #b8b9bb !important; }

.bgc--gray-6-darker {
  background-color: #a6a7aa !important; }

.bgc--gray-6-lighter {
  background-color: #cacbcc !important; }

.fc--gray-7 {
  color: #c6c7c9 !important;
  fill: #c6c7c9 !important; }

.fc--gray-7-darker {
  color: #b4b5b8 !important;
  fill: #b4b5b8 !important; }

.fc--gray-7-lighter {
  color: #d8d9da !important;
  fill: #d8d9da !important; }

.bgc--gray-7 {
  background-color: #c6c7c9 !important; }

.bgc--gray-7-darker {
  background-color: #b4b5b8 !important; }

.bgc--gray-7-lighter {
  background-color: #d8d9da !important; }

.fc--gray-8 {
  color: #d4d5d6 !important;
  fill: #d4d5d6 !important; }

.fc--gray-8-darker {
  color: #c2c3c5 !important;
  fill: #c2c3c5 !important; }

.fc--gray-8-lighter {
  color: #e6e7e7 !important;
  fill: #e6e7e7 !important; }

.bgc--gray-8 {
  background-color: #d4d5d6 !important; }

.bgc--gray-8-darker {
  background-color: #c2c3c5 !important; }

.bgc--gray-8-lighter {
  background-color: #e6e7e7 !important; }

.fc--gray-9 {
  color: #e3e3e4 !important;
  fill: #e3e3e4 !important; }

.fc--gray-9-darker {
  color: #d1d1d2 !important;
  fill: #d1d1d2 !important; }

.fc--gray-9-lighter {
  color: #f5f5f6 !important;
  fill: #f5f5f6 !important; }

.bgc--gray-9 {
  background-color: #e3e3e4 !important; }

.bgc--gray-9-darker {
  background-color: #d1d1d2 !important; }

.bgc--gray-9-lighter {
  background-color: #f5f5f6 !important; }

.fc--gray-10 {
  color: #f0f1f1 !important;
  fill: #f0f1f1 !important; }

.fc--gray-10-darker {
  color: #dee0e0 !important;
  fill: #dee0e0 !important; }

.fc--gray-10-lighter {
  color: white !important;
  fill: white !important; }

.bgc--gray-10 {
  background-color: #f0f1f1 !important; }

.bgc--gray-10-darker {
  background-color: #dee0e0 !important; }

.bgc--gray-10-lighter {
  background-color: white !important; }

.fc--error {
  color: #D8393C !important;
  fill: #D8393C !important; }

.fc--error-darker {
  color: #c6272a !important;
  fill: #c6272a !important; }

.fc--error-lighter {
  color: #de5759 !important;
  fill: #de5759 !important; }

.bgc--error {
  background-color: #D8393C !important; }

.bgc--error-darker {
  background-color: #c6272a !important; }

.bgc--error-lighter {
  background-color: #de5759 !important; }

.fc--error-fill {
  color: #FAE5E7 !important;
  fill: #FAE5E7 !important; }

.fc--error-fill-darker {
  color: #f4c7cb !important;
  fill: #f4c7cb !important; }

.fc--error-fill-lighter {
  color: white !important;
  fill: white !important; }

.bgc--error-fill {
  background-color: #FAE5E7 !important; }

.bgc--error-fill-darker {
  background-color: #f4c7cb !important; }

.bgc--error-fill-lighter {
  background-color: white !important; }

.fc--warning {
  color: #FF9904 !important;
  fill: #FF9904 !important; }

.fc--warning-darker {
  color: #df8500 !important;
  fill: #df8500 !important; }

.fc--warning-lighter {
  color: #ffa828 !important;
  fill: #ffa828 !important; }

.bgc--warning {
  background-color: #FF9904 !important; }

.bgc--warning-darker {
  background-color: #df8500 !important; }

.bgc--warning-lighter {
  background-color: #ffa828 !important; }

.fc--warning-fill {
  color: #fdedd6 !important;
  fill: #fdedd6 !important; }

.fc--warning-fill-darker {
  color: #fbdeb4 !important;
  fill: #fbdeb4 !important; }

.fc--warning-fill-lighter {
  color: #fffcf8 !important;
  fill: #fffcf8 !important; }

.bgc--warning-fill {
  background-color: #fdedd6 !important; }

.bgc--warning-fill-darker {
  background-color: #fbdeb4 !important; }

.bgc--warning-fill-lighter {
  background-color: #fffcf8 !important; }

.fc--success {
  color: #4a9864 !important;
  fill: #4a9864 !important; }

.fc--success-darker {
  color: #3e8054 !important;
  fill: #3e8054 !important; }

.fc--success-lighter {
  color: #58ae75 !important;
  fill: #58ae75 !important; }

.bgc--success {
  background-color: #4a9864 !important; }

.bgc--success-darker {
  background-color: #3e8054 !important; }

.bgc--success-lighter {
  background-color: #58ae75 !important; }

.fc--success-fill {
  color: #c4decd !important;
  fill: #c4decd !important; }

.fc--success-fill-darker {
  color: #add1ba !important;
  fill: #add1ba !important; }

.fc--success-fill-lighter {
  color: #dbebe0 !important;
  fill: #dbebe0 !important; }

.bgc--success-fill {
  background-color: #c4decd !important; }

.bgc--success-fill-darker {
  background-color: #add1ba !important; }

.bgc--success-fill-lighter {
  background-color: #dbebe0 !important; }

.fc--white {
  color: #ffffff !important;
  fill: #ffffff !important; }

.fc--white-darker {
  color: #ededed !important;
  fill: #ededed !important; }

.fc--white-lighter {
  color: white !important;
  fill: white !important; }

.bgc--white {
  background-color: #ffffff !important; }

.bgc--white-darker {
  background-color: #ededed !important; }

.bgc--white-lighter {
  background-color: white !important; }

/**
 *
 * TYPOGRAPHY: VARS
 *
 */
/**
 *
 * TYPOGRAPHY: FUNCTIONS
 *
 */
.progress {
  display: block;
  width: 100%;
  height: 5px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000000;
  /*
	 *
	 * Define progress */ }
  .progress .progress__bar {
    display: block;
    width: 0%;
    height: 100%;
    background: #0C6196;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0 0 4px 0;
    transition: width 150ms; }
  .progress[data-amount='1'] .progress__bar {
    width: 1%; }
  .progress[data-amount='2'] .progress__bar {
    width: 2%; }
  .progress[data-amount='3'] .progress__bar {
    width: 3%; }
  .progress[data-amount='4'] .progress__bar {
    width: 4%; }
  .progress[data-amount='5'] .progress__bar {
    width: 5%; }
  .progress[data-amount='6'] .progress__bar {
    width: 6%; }
  .progress[data-amount='7'] .progress__bar {
    width: 7%; }
  .progress[data-amount='8'] .progress__bar {
    width: 8%; }
  .progress[data-amount='9'] .progress__bar {
    width: 9%; }
  .progress[data-amount='10'] .progress__bar {
    width: 10%; }
  .progress[data-amount='11'] .progress__bar {
    width: 11%; }
  .progress[data-amount='12'] .progress__bar {
    width: 12%; }
  .progress[data-amount='13'] .progress__bar {
    width: 13%; }
  .progress[data-amount='14'] .progress__bar {
    width: 14%; }
  .progress[data-amount='15'] .progress__bar {
    width: 15%; }
  .progress[data-amount='16'] .progress__bar {
    width: 16%; }
  .progress[data-amount='17'] .progress__bar {
    width: 17%; }
  .progress[data-amount='18'] .progress__bar {
    width: 18%; }
  .progress[data-amount='19'] .progress__bar {
    width: 19%; }
  .progress[data-amount='20'] .progress__bar {
    width: 20%; }
  .progress[data-amount='21'] .progress__bar {
    width: 21%; }
  .progress[data-amount='22'] .progress__bar {
    width: 22%; }
  .progress[data-amount='23'] .progress__bar {
    width: 23%; }
  .progress[data-amount='24'] .progress__bar {
    width: 24%; }
  .progress[data-amount='25'] .progress__bar {
    width: 25%; }
  .progress[data-amount='26'] .progress__bar {
    width: 26%; }
  .progress[data-amount='27'] .progress__bar {
    width: 27%; }
  .progress[data-amount='28'] .progress__bar {
    width: 28%; }
  .progress[data-amount='29'] .progress__bar {
    width: 29%; }
  .progress[data-amount='30'] .progress__bar {
    width: 30%; }
  .progress[data-amount='31'] .progress__bar {
    width: 31%; }
  .progress[data-amount='32'] .progress__bar {
    width: 32%; }
  .progress[data-amount='33'] .progress__bar {
    width: 33%; }
  .progress[data-amount='34'] .progress__bar {
    width: 34%; }
  .progress[data-amount='35'] .progress__bar {
    width: 35%; }
  .progress[data-amount='36'] .progress__bar {
    width: 36%; }
  .progress[data-amount='37'] .progress__bar {
    width: 37%; }
  .progress[data-amount='38'] .progress__bar {
    width: 38%; }
  .progress[data-amount='39'] .progress__bar {
    width: 39%; }
  .progress[data-amount='40'] .progress__bar {
    width: 40%; }
  .progress[data-amount='41'] .progress__bar {
    width: 41%; }
  .progress[data-amount='42'] .progress__bar {
    width: 42%; }
  .progress[data-amount='43'] .progress__bar {
    width: 43%; }
  .progress[data-amount='44'] .progress__bar {
    width: 44%; }
  .progress[data-amount='45'] .progress__bar {
    width: 45%; }
  .progress[data-amount='46'] .progress__bar {
    width: 46%; }
  .progress[data-amount='47'] .progress__bar {
    width: 47%; }
  .progress[data-amount='48'] .progress__bar {
    width: 48%; }
  .progress[data-amount='49'] .progress__bar {
    width: 49%; }
  .progress[data-amount='50'] .progress__bar {
    width: 50%; }
  .progress[data-amount='51'] .progress__bar {
    width: 51%; }
  .progress[data-amount='52'] .progress__bar {
    width: 52%; }
  .progress[data-amount='53'] .progress__bar {
    width: 53%; }
  .progress[data-amount='54'] .progress__bar {
    width: 54%; }
  .progress[data-amount='55'] .progress__bar {
    width: 55%; }
  .progress[data-amount='56'] .progress__bar {
    width: 56%; }
  .progress[data-amount='57'] .progress__bar {
    width: 57%; }
  .progress[data-amount='58'] .progress__bar {
    width: 58%; }
  .progress[data-amount='59'] .progress__bar {
    width: 59%; }
  .progress[data-amount='60'] .progress__bar {
    width: 60%; }
  .progress[data-amount='61'] .progress__bar {
    width: 61%; }
  .progress[data-amount='62'] .progress__bar {
    width: 62%; }
  .progress[data-amount='63'] .progress__bar {
    width: 63%; }
  .progress[data-amount='64'] .progress__bar {
    width: 64%; }
  .progress[data-amount='65'] .progress__bar {
    width: 65%; }
  .progress[data-amount='66'] .progress__bar {
    width: 66%; }
  .progress[data-amount='67'] .progress__bar {
    width: 67%; }
  .progress[data-amount='68'] .progress__bar {
    width: 68%; }
  .progress[data-amount='69'] .progress__bar {
    width: 69%; }
  .progress[data-amount='70'] .progress__bar {
    width: 70%; }
  .progress[data-amount='71'] .progress__bar {
    width: 71%; }
  .progress[data-amount='72'] .progress__bar {
    width: 72%; }
  .progress[data-amount='73'] .progress__bar {
    width: 73%; }
  .progress[data-amount='74'] .progress__bar {
    width: 74%; }
  .progress[data-amount='75'] .progress__bar {
    width: 75%; }
  .progress[data-amount='76'] .progress__bar {
    width: 76%; }
  .progress[data-amount='77'] .progress__bar {
    width: 77%; }
  .progress[data-amount='78'] .progress__bar {
    width: 78%; }
  .progress[data-amount='79'] .progress__bar {
    width: 79%; }
  .progress[data-amount='80'] .progress__bar {
    width: 80%; }
  .progress[data-amount='81'] .progress__bar {
    width: 81%; }
  .progress[data-amount='82'] .progress__bar {
    width: 82%; }
  .progress[data-amount='83'] .progress__bar {
    width: 83%; }
  .progress[data-amount='84'] .progress__bar {
    width: 84%; }
  .progress[data-amount='85'] .progress__bar {
    width: 85%; }
  .progress[data-amount='86'] .progress__bar {
    width: 86%; }
  .progress[data-amount='87'] .progress__bar {
    width: 87%; }
  .progress[data-amount='88'] .progress__bar {
    width: 88%; }
  .progress[data-amount='89'] .progress__bar {
    width: 89%; }
  .progress[data-amount='90'] .progress__bar {
    width: 90%; }
  .progress[data-amount='91'] .progress__bar {
    width: 91%; }
  .progress[data-amount='92'] .progress__bar {
    width: 92%; }
  .progress[data-amount='93'] .progress__bar {
    width: 93%; }
  .progress[data-amount='94'] .progress__bar {
    width: 94%; }
  .progress[data-amount='95'] .progress__bar {
    width: 95%; }
  .progress[data-amount='96'] .progress__bar {
    width: 96%; }
  .progress[data-amount='97'] .progress__bar {
    width: 97%; }
  .progress[data-amount='98'] .progress__bar {
    width: 98%; }
  .progress[data-amount='99'] .progress__bar {
    width: 99%; }
  .progress[data-amount='100'] .progress__bar {
    width: 100%; }
