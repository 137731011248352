@import "../../../01_fundaments/utils";

.RCDetails {

    .RCDetails__inner { }

    .paragraph { padding: 0; line-height: 1.45; }

    hr { border: none; border-bottom: 1px solid color('gray-9'); margin: 15px 0; }

}