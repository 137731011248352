@import './__settings/__vars.scss';
@import './__settings/_functions.scss';

/**
 *
 * COLORS: GENERAL
 *
 */

body {
	background: color('gray-10');
	color: color('gray-1');
}

::selection {
	color: #fff;
	background: color('primary-1');
}

::-moz-selection {
	color: #fff;
	background: color('primary-1');
}

a { color: color('primary-1'); }