/**
 *
 * BOXING: VARS
 *
 */
/**
 *
 * BOXING: FUNCTIONS
 *
 */
/**
 *
 * BOXING: MIXINS
 *
 */
/**
 *
 * COLORS: VARS
 *
 */
/**
 *
 * COLORS: FUNCTIONS
 *
 */
/**
 *
 * COLORS: VARS
 *
 */
/**
 *
 * COLORS: FUNCTIONS
 *
 */
/**
 *
 * COLORS: INLINES
 *
 */
.fc--primary-1 {
  color: #0C6196 !important;
  fill: #0C6196 !important; }

.fc--primary-1-darker {
  color: #094c75 !important;
  fill: #094c75 !important; }

.fc--primary-1-lighter {
  color: #0f76b7 !important;
  fill: #0f76b7 !important; }

.bgc--primary-1 {
  background-color: #0C6196 !important; }

.bgc--primary-1-darker {
  background-color: #094c75 !important; }

.bgc--primary-1-lighter {
  background-color: #0f76b7 !important; }

.fc--primary-1-light {
  color: #137cbd !important;
  fill: #137cbd !important; }

.fc--primary-1-light-darker {
  color: #10679d !important;
  fill: #10679d !important; }

.fc--primary-1-light-lighter {
  color: #1691dd !important;
  fill: #1691dd !important; }

.bgc--primary-1-light {
  background-color: #137cbd !important; }

.bgc--primary-1-light-darker {
  background-color: #10679d !important; }

.bgc--primary-1-light-lighter {
  background-color: #1691dd !important; }

.fc--secondary-1 {
  color: #99BACB !important;
  fill: #99BACB !important; }

.fc--secondary-1-darker {
  color: #81aabf !important;
  fill: #81aabf !important; }

.fc--secondary-1-lighter {
  color: #b1cad7 !important;
  fill: #b1cad7 !important; }

.bgc--secondary-1 {
  background-color: #99BACB !important; }

.bgc--secondary-1-darker {
  background-color: #81aabf !important; }

.bgc--secondary-1-lighter {
  background-color: #b1cad7 !important; }

.fc--secondary-1-light {
  color: #F8FCFE !important;
  fill: #F8FCFE !important; }

.fc--secondary-1-light-darker {
  color: #d9effa !important;
  fill: #d9effa !important; }

.fc--secondary-1-light-lighter {
  color: white !important;
  fill: white !important; }

.bgc--secondary-1-light {
  background-color: #F8FCFE !important; }

.bgc--secondary-1-light-darker {
  background-color: #d9effa !important; }

.bgc--secondary-1-light-lighter {
  background-color: white !important; }

.fc--secondary-2 {
  color: #31a175 !important;
  fill: #31a175 !important; }

.fc--secondary-2-darker {
  color: #298661 !important;
  fill: #298661 !important; }

.fc--secondary-2-lighter {
  color: #39bc89 !important;
  fill: #39bc89 !important; }

.bgc--secondary-2 {
  background-color: #31a175 !important; }

.bgc--secondary-2-darker {
  background-color: #298661 !important; }

.bgc--secondary-2-lighter {
  background-color: #39bc89 !important; }

.fc--secondary-2-light {
  color: #42ad83 !important;
  fill: #42ad83 !important; }

.fc--secondary-2-light-darker {
  color: #38936f !important;
  fill: #38936f !important; }

.fc--secondary-2-light-lighter {
  color: #55be95 !important;
  fill: #55be95 !important; }

.bgc--secondary-2-light {
  background-color: #42ad83 !important; }

.bgc--secondary-2-light-darker {
  background-color: #38936f !important; }

.bgc--secondary-2-light-lighter {
  background-color: #55be95 !important; }

.fc--gray-1 {
  color: #444444 !important;
  fill: #444444 !important; }

.fc--gray-1-darker {
  color: #323232 !important;
  fill: #323232 !important; }

.fc--gray-1-lighter {
  color: #565656 !important;
  fill: #565656 !important; }

.bgc--gray-1 {
  background-color: #444444 !important; }

.bgc--gray-1-darker {
  background-color: #323232 !important; }

.bgc--gray-1-lighter {
  background-color: #565656 !important; }

.fc--gray-2 {
  color: #717477 !important;
  fill: #717477 !important; }

.fc--gray-2-darker {
  color: #606265 !important;
  fill: #606265 !important; }

.fc--gray-2-lighter {
  color: #838689 !important;
  fill: #838689 !important; }

.bgc--gray-2 {
  background-color: #717477 !important; }

.bgc--gray-2-darker {
  background-color: #606265 !important; }

.bgc--gray-2-lighter {
  background-color: #838689 !important; }

.fc--gray-3 {
  color: #8a8d8f !important;
  fill: #8a8d8f !important; }

.fc--gray-3-darker {
  color: #787b7d !important;
  fill: #787b7d !important; }

.fc--gray-3-lighter {
  color: #9c9fa0 !important;
  fill: #9c9fa0 !important; }

.bgc--gray-3 {
  background-color: #8a8d8f !important; }

.bgc--gray-3-darker {
  background-color: #787b7d !important; }

.bgc--gray-3-lighter {
  background-color: #9c9fa0 !important; }

.fc--gray-4 {
  color: #97999b !important;
  fill: #97999b !important; }

.fc--gray-4-darker {
  color: #85878a !important;
  fill: #85878a !important; }

.fc--gray-4-lighter {
  color: #a9abad !important;
  fill: #a9abad !important; }

.bgc--gray-4 {
  background-color: #97999b !important; }

.bgc--gray-4-darker {
  background-color: #85878a !important; }

.bgc--gray-4-lighter {
  background-color: #a9abad !important; }

.fc--gray-5 {
  color: #a5a7a8 !important;
  fill: #a5a7a8 !important; }

.fc--gray-5-darker {
  color: #939596 !important;
  fill: #939596 !important; }

.fc--gray-5-lighter {
  color: #b7b9ba !important;
  fill: #b7b9ba !important; }

.bgc--gray-5 {
  background-color: #a5a7a8 !important; }

.bgc--gray-5-darker {
  background-color: #939596 !important; }

.bgc--gray-5-lighter {
  background-color: #b7b9ba !important; }

.fc--gray-6 {
  color: #b8b9bb !important;
  fill: #b8b9bb !important; }

.fc--gray-6-darker {
  color: #a6a7aa !important;
  fill: #a6a7aa !important; }

.fc--gray-6-lighter {
  color: #cacbcc !important;
  fill: #cacbcc !important; }

.bgc--gray-6 {
  background-color: #b8b9bb !important; }

.bgc--gray-6-darker {
  background-color: #a6a7aa !important; }

.bgc--gray-6-lighter {
  background-color: #cacbcc !important; }

.fc--gray-7 {
  color: #c6c7c9 !important;
  fill: #c6c7c9 !important; }

.fc--gray-7-darker {
  color: #b4b5b8 !important;
  fill: #b4b5b8 !important; }

.fc--gray-7-lighter {
  color: #d8d9da !important;
  fill: #d8d9da !important; }

.bgc--gray-7 {
  background-color: #c6c7c9 !important; }

.bgc--gray-7-darker {
  background-color: #b4b5b8 !important; }

.bgc--gray-7-lighter {
  background-color: #d8d9da !important; }

.fc--gray-8 {
  color: #d4d5d6 !important;
  fill: #d4d5d6 !important; }

.fc--gray-8-darker {
  color: #c2c3c5 !important;
  fill: #c2c3c5 !important; }

.fc--gray-8-lighter {
  color: #e6e7e7 !important;
  fill: #e6e7e7 !important; }

.bgc--gray-8 {
  background-color: #d4d5d6 !important; }

.bgc--gray-8-darker {
  background-color: #c2c3c5 !important; }

.bgc--gray-8-lighter {
  background-color: #e6e7e7 !important; }

.fc--gray-9 {
  color: #e3e3e4 !important;
  fill: #e3e3e4 !important; }

.fc--gray-9-darker {
  color: #d1d1d2 !important;
  fill: #d1d1d2 !important; }

.fc--gray-9-lighter {
  color: #f5f5f6 !important;
  fill: #f5f5f6 !important; }

.bgc--gray-9 {
  background-color: #e3e3e4 !important; }

.bgc--gray-9-darker {
  background-color: #d1d1d2 !important; }

.bgc--gray-9-lighter {
  background-color: #f5f5f6 !important; }

.fc--gray-10 {
  color: #f0f1f1 !important;
  fill: #f0f1f1 !important; }

.fc--gray-10-darker {
  color: #dee0e0 !important;
  fill: #dee0e0 !important; }

.fc--gray-10-lighter {
  color: white !important;
  fill: white !important; }

.bgc--gray-10 {
  background-color: #f0f1f1 !important; }

.bgc--gray-10-darker {
  background-color: #dee0e0 !important; }

.bgc--gray-10-lighter {
  background-color: white !important; }

.fc--error {
  color: #D8393C !important;
  fill: #D8393C !important; }

.fc--error-darker {
  color: #c6272a !important;
  fill: #c6272a !important; }

.fc--error-lighter {
  color: #de5759 !important;
  fill: #de5759 !important; }

.bgc--error {
  background-color: #D8393C !important; }

.bgc--error-darker {
  background-color: #c6272a !important; }

.bgc--error-lighter {
  background-color: #de5759 !important; }

.fc--error-fill {
  color: #FAE5E7 !important;
  fill: #FAE5E7 !important; }

.fc--error-fill-darker {
  color: #f4c7cb !important;
  fill: #f4c7cb !important; }

.fc--error-fill-lighter {
  color: white !important;
  fill: white !important; }

.bgc--error-fill {
  background-color: #FAE5E7 !important; }

.bgc--error-fill-darker {
  background-color: #f4c7cb !important; }

.bgc--error-fill-lighter {
  background-color: white !important; }

.fc--warning {
  color: #FF9904 !important;
  fill: #FF9904 !important; }

.fc--warning-darker {
  color: #df8500 !important;
  fill: #df8500 !important; }

.fc--warning-lighter {
  color: #ffa828 !important;
  fill: #ffa828 !important; }

.bgc--warning {
  background-color: #FF9904 !important; }

.bgc--warning-darker {
  background-color: #df8500 !important; }

.bgc--warning-lighter {
  background-color: #ffa828 !important; }

.fc--warning-fill {
  color: #fdedd6 !important;
  fill: #fdedd6 !important; }

.fc--warning-fill-darker {
  color: #fbdeb4 !important;
  fill: #fbdeb4 !important; }

.fc--warning-fill-lighter {
  color: #fffcf8 !important;
  fill: #fffcf8 !important; }

.bgc--warning-fill {
  background-color: #fdedd6 !important; }

.bgc--warning-fill-darker {
  background-color: #fbdeb4 !important; }

.bgc--warning-fill-lighter {
  background-color: #fffcf8 !important; }

.fc--success {
  color: #4a9864 !important;
  fill: #4a9864 !important; }

.fc--success-darker {
  color: #3e8054 !important;
  fill: #3e8054 !important; }

.fc--success-lighter {
  color: #58ae75 !important;
  fill: #58ae75 !important; }

.bgc--success {
  background-color: #4a9864 !important; }

.bgc--success-darker {
  background-color: #3e8054 !important; }

.bgc--success-lighter {
  background-color: #58ae75 !important; }

.fc--success-fill {
  color: #c4decd !important;
  fill: #c4decd !important; }

.fc--success-fill-darker {
  color: #add1ba !important;
  fill: #add1ba !important; }

.fc--success-fill-lighter {
  color: #dbebe0 !important;
  fill: #dbebe0 !important; }

.bgc--success-fill {
  background-color: #c4decd !important; }

.bgc--success-fill-darker {
  background-color: #add1ba !important; }

.bgc--success-fill-lighter {
  background-color: #dbebe0 !important; }

.fc--white {
  color: #ffffff !important;
  fill: #ffffff !important; }

.fc--white-darker {
  color: #ededed !important;
  fill: #ededed !important; }

.fc--white-lighter {
  color: white !important;
  fill: white !important; }

.bgc--white {
  background-color: #ffffff !important; }

.bgc--white-darker {
  background-color: #ededed !important; }

.bgc--white-lighter {
  background-color: white !important; }

/**
 *
 * TYPOGRAPHY: VARS
 *
 */
/**
 *
 * TYPOGRAPHY: FUNCTIONS
 *
 */
.invoicePDF .invoicePDF__page {
  width: 100vw;
  height: 100vh;
  padding: 15px;
  background: #ffffff;
  margin: 0;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  overflow: hidden;
  font-size: 12px;
  line-height: 1.8;
  background: #fff; }
  .invoicePDF .invoicePDF__page:after {
    content: "";
    display: block;
    position: absolute;
    left: 15px;
    top: 15px;
    right: 15px;
    bottom: 15px;
    background: #ffffff;
    z-index: 1; }

.invoicePDF .invoicePDF__header {
  padding: 65px; }

.invoicePDF .invoicePDF__lines {
  padding: 0 65px 65px; }

.invoicePDF .invoicePDF__totals {
  padding: 65px; }
  .invoicePDF .invoicePDF__totals.type-compact {
    padding-top: 30px;
    padding-bottom: 30px; }

.invoicePDF .invoicePDF__inner {
  width: 100%;
  height: 100%;
  padding-bottom: 190px;
  position: relative;
  z-index: 2; }

.invoicePDF .invoicePDF__basics {
  display: flex;
  justify-content: space-between;
  margin: 55px 0 0 0; }
  .invoicePDF .invoicePDF__basics strong {
    text-transform: none;
    color: #0C6196; }
  .invoicePDF .invoicePDF__basics h1 {
    font-size: 22px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 2px 0;
    color: #0C6196; }
  .invoicePDF .invoicePDF__basics .column {
    width: 50%; }
    .invoicePDF .invoicePDF__basics .column.small-text {
      padding-top: 8px;
      font-size: 10px;
      line-height: 1.55;
      color: #a6a6a6; }
      .invoicePDF .invoicePDF__basics .column.small-text strong {
        color: #a6a6a6; }
    .invoicePDF .invoicePDF__basics .column.text-right {
      text-align: right; }
  .invoicePDF .invoicePDF__basics:first-child {
    margin-top: 0; }

.invoicePDF .invoicePDF__brand {
  width: 50vw;
  margin: 0; }
  .invoicePDF .invoicePDF__brand img {
    display: block;
    width: 100%; }

.invoicePDF .invoicePDF__essentials {
  display: flex;
  width: 100%;
  background: #f0f8fe;
  margin: 30px 0 0 0;
  font-size: 9px;
  line-height: 1.4; }
  .invoicePDF .invoicePDF__essentials .column {
    width: 25%;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    padding: 7px 12px; }
    .invoicePDF .invoicePDF__essentials .column strong {
      font-size: 11px;
      color: #0C6196; }
    .invoicePDF .invoicePDF__essentials .column:first-child {
      border-left: none; }

.invoicePDF .invoicePDF__lines table {
  width: 100%;
  border-collapse: collapse;
  margin: 45px 0 0 0; }
  .invoicePDF .invoicePDF__lines table:first-child {
    margin-top: 0; }

.invoicePDF .invoicePDF__lines th {
  font-size: 12px;
  font-weight: 700; }

.invoicePDF .invoicePDF__lines th, .invoicePDF .invoicePDF__lines td {
  text-align: center; }
  .invoicePDF .invoicePDF__lines th:first-child, .invoicePDF .invoicePDF__lines td:first-child {
    text-align: left;
    padding-left: 12px; }
  .invoicePDF .invoicePDF__lines th:last-child, .invoicePDF .invoicePDF__lines td:last-child {
    text-align: right;
    padding-right: 12px; }

.invoicePDF .invoicePDF__lines thead tr {
  border-bottom: 1px solid #0C6196; }

.invoicePDF .invoicePDF__lines thead th {
  padding-bottom: 5px;
  color: #0C6196; }

.invoicePDF .invoicePDF__lines table:first-child tbody tr:first-child td {
  padding-top: 18px; }

.invoicePDF .invoicePDF__lines table:first-child tbody tr:nth-child(2n) {
  background: #f0f8fe; }

.invoicePDF .invoicePDF__lines table:first-child tbody td {
  padding-top: 14px;
  padding-bottom: 14px;
  line-height: 1.2; }
  .invoicePDF .invoicePDF__lines table:first-child tbody td:nth-child(1) {
    width: 41.66667%; }
  .invoicePDF .invoicePDF__lines table:first-child tbody td:nth-child(2) {
    width: 8.33333%; }
  .invoicePDF .invoicePDF__lines table:first-child tbody td:nth-child(3) {
    width: 16.66667%; }
  .invoicePDF .invoicePDF__lines table:first-child tbody td:nth-child(4) {
    width: 8.33333%; }
  .invoicePDF .invoicePDF__lines table:first-child tbody td:nth-child(5) {
    width: 8.33333%; }
  .invoicePDF .invoicePDF__lines table:first-child tbody td:nth-child(6) {
    width: 16.66667%; }

.invoicePDF .invoicePDF__lines table:first-child tbody em {
  font-style: normal;
  font-size: 8px;
  line-height: 1;
  opacity: 0.55; }

.invoicePDF .invoicePDF__totals {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #f7f7f7; }
  .invoicePDF .invoicePDF__totals .invoicePDF__totalsNote {
    margin: 0 0 25px 0; }
  .invoicePDF .invoicePDF__totals .invoicePDF__totalsColumns {
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
  .invoicePDF .invoicePDF__totals ul {
    width: 100%;
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding: 0; }
  .invoicePDF .invoicePDF__totals li {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 5px 0 0 0;
    padding: 5px 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.03); }
    .invoicePDF .invoicePDF__totals li strong {
      color: #0C6196; }
    .invoicePDF .invoicePDF__totals li span {
      display: block;
      width: 95px; }
    .invoicePDF .invoicePDF__totals li:first-child {
      padding-top: 0;
      margin-top: 0;
      border: none; }
  .invoicePDF .invoicePDF__totals .column {
    display: flex;
    width: 38%;
    flex-wrap: wrap; }
    .invoicePDF .invoicePDF__totals .column div {
      width: 100%; }
    .invoicePDF .invoicePDF__totals .column:nth-child(2n) {
      width: 40%;
      text-align: right;
      justify-content: flex-end; }
    .invoicePDF .invoicePDF__totals .column.text-small {
      font-size: 11px;
      line-height: 1.4;
      color: #a6a6a6; }

.invoicePDF .invoicePDF__compactFooter {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px; }

.invoicePDF .invoicePDF__pageIndication {
  font-size: 9px;
  line-height: 1.2;
  opacity: 0.5;
  padding: 0 0 0 12px; }

.invoicePDF .invoicePDF__pageNumber {
  font-size: 9px;
  line-height: 1.2;
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0 0 0 20px; }
  .invoicePDF .invoicePDF__pageNumber.position-fixed {
    position: absolute;
    right: 75px;
    bottom: 20px;
    z-index: 3; }

.invoicePDF .invoicePDF__compactFooterInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  font-size: 11px;
  line-height: 1.1; }
  .invoicePDF .invoicePDF__compactFooterInfo strong {
    margin: 0; }

.invoicePDF .invoicePDF__compactFooterInfoRow {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 3px 0 0 0; }
  .invoicePDF .invoicePDF__compactFooterInfoRow div {
    padding: 0 0 0 8px;
    margin: 0 0 0 8px;
    border-left: 1px solid #c6c7c9; }
    .invoicePDF .invoicePDF__compactFooterInfoRow div:first-child {
      margin: 0;
      padding: 0;
      border: none; }
  .invoicePDF .invoicePDF__compactFooterInfoRow:first-child {
    margin-top: 0; }
