@import "../../../01_fundaments/utils";

.RCTabs { background: color('gray-10');

    .RCTabs__inner { padding: 5px 5px 0; }

    .RCTabs__list {

        ul { list-style: none; margin: 0; padding: 0; display: flex; align-items: flex-end; justify-content: flex-start; flex-wrap: wrap; }

        li { font-size: 12px; line-height: 1.2; margin: 0 0 0 2px; @include noselect();
        
            &:first-child { margin-left: 0; }

        }

        a, button { display: flex; align-items: center; justify-content: center; padding: 8px 15px; background: none; color: color('gray-1'); border-radius: 8px 8px 0 0; cursor: pointer; appearance: none; border: none;
            
            &:hover { color: color('primary-1'); }

            &:focus, &:active { outline: none; }
        
        }

        i { font-size: 20px; margin: 0 8px 0 0; }

        .is-active {
            
            a, button { background: #fff; cursor: default; }

            a:hover, button:hover { color: color('gray-1'); }

        }

    }

}