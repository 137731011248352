@import './__settings/__vars.scss';
@import './__settings/_functions.scss';

/**
 *
 * COLORS: INLINES
 *
 */

// loop through the colors list (see settings)
// and print the font color and background-color for each

@each $colorName, $colorValue, $color in $colors {

	.fc--#{$colorName} 			{ color: color(#{$colorName}) !important; fill: color(#{$colorName}) !important; }
	.fc--#{$colorName}-darker 	{ color: color(#{$colorName}, darker) !important; fill: color(#{$colorName}, darker) !important; }
	.fc--#{$colorName}-lighter 	{ color: color(#{$colorName}, lighter) !important; fill: color(#{$colorName}, lighter) !important; }

	.bgc--#{$colorName} 		{ background-color: color(#{$colorName}) !important; }
	.bgc--#{$colorName}-darker 	{ background-color: color(#{$colorName}, darker) !important; }
	.bgc--#{$colorName}-lighter { background-color: color(#{$colorName}, lighter) !important; }

}