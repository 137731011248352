
.loaderContainer { display: flex; flex-wrap: wrap; width: 100%; height: 100%; position: relative;

	.loaderContainer__content { width: 100%; transition: opacity 150ms; }

	.loaderContainer__loader { display: flex; width: 100%; height: 100%; align-items: center; justify-content: center; opacity: 0; pointer-events: none; transition: opacity 150ms; position: absolute; left: 0; top: 0; z-index: 2; }



	/*
	 *
	 *
	 *
	 * Define sizes */
	&.is-loading {

		.loaderContainer__content { opacity: 0.15; }

		.loaderContainer__loader { opacity: 1; }

	}



	/*
	 *
	 *
	 *
	 * Define variations */
	&.source-button {

		.loaderContainer__content { display: flex; align-items: center; justify-content: center;

			i:first-child { margin-right: 8px; }

		}

	}

}