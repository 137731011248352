.RCDocuments { }

.RCDocument { display: flex; align-items: center; justify-content: space-between; padding: 15px 20px; background: #f9f9f9;

    .RCDocument__info { display: flex; align-items: center; justify-content: flex-start; width: 100%; }

    .RCDocument__visual { width: 75px; height: 95px; background: color('gray-9'); position: relative; border: 1px solid color('gray-5'); flex-shrink: 0; flex-grow: 0;
    
        img { display: block; width: 100%; height: 100%; position: absolute; left: 0; top: 0; object-fit: cover; object-position: center center; }

    }

    .RCDocument__content { font-size: 12.9px; line-height: 1.7; text-align: left; padding: 0 0 0 15px; overflow: hidden;
        
        .paragraph { padding: 0; }
    
    }

    .RCDocument__actions { width: 125px; flex-grow: 0; flex-shrink: 0; text-align: right; position: relative; top: 5px;
        
        .paragraph { display: flex; align-items: center; justify-content: flex-end; }

        svg { display: block; width: 14px; height: 14px; margin: 0 5px 0 0; position: relative; top: -2px; opacity: 0.5; }
    
    }

    &:nth-child(even) { background: #fff; }

}