/**
 *
 * BOXING: GENERAL
 *
 */

html {
	display: block; width: 100%; height: 100%; box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	display: block; width: 100%; height: 100%; margin: 0; padding: 0;
}

p { margin: 0 0 15px 0;

	&:last-child { margin-bottom: 0; }

}

form { width: 100%; }

.section { width: 100%; position: relative;

	.section__inner { width: 100%; max-width: 1280px; min-width: 768px; margin: 0 auto; padding-left: 15px; padding-right: 15px; position: relative; }

}