/**
 *
 * BOXING: VARS
 *
 */
/**
 *
 * BOXING: FUNCTIONS
 *
 */
/**
 *
 * BOXING: MIXINS
 *
 */
/**
 *
 * COLORS: VARS
 *
 */
/**
 *
 * COLORS: FUNCTIONS
 *
 */
/**
 *
 * COLORS: VARS
 *
 */
/**
 *
 * COLORS: FUNCTIONS
 *
 */
/**
 *
 * COLORS: INLINES
 *
 */
.fc--primary-1 {
  color: #0C6196 !important;
  fill: #0C6196 !important; }

.fc--primary-1-darker {
  color: #094c75 !important;
  fill: #094c75 !important; }

.fc--primary-1-lighter {
  color: #0f76b7 !important;
  fill: #0f76b7 !important; }

.bgc--primary-1 {
  background-color: #0C6196 !important; }

.bgc--primary-1-darker {
  background-color: #094c75 !important; }

.bgc--primary-1-lighter {
  background-color: #0f76b7 !important; }

.fc--primary-1-light {
  color: #137cbd !important;
  fill: #137cbd !important; }

.fc--primary-1-light-darker {
  color: #10679d !important;
  fill: #10679d !important; }

.fc--primary-1-light-lighter {
  color: #1691dd !important;
  fill: #1691dd !important; }

.bgc--primary-1-light {
  background-color: #137cbd !important; }

.bgc--primary-1-light-darker {
  background-color: #10679d !important; }

.bgc--primary-1-light-lighter {
  background-color: #1691dd !important; }

.fc--secondary-1 {
  color: #99BACB !important;
  fill: #99BACB !important; }

.fc--secondary-1-darker {
  color: #81aabf !important;
  fill: #81aabf !important; }

.fc--secondary-1-lighter {
  color: #b1cad7 !important;
  fill: #b1cad7 !important; }

.bgc--secondary-1 {
  background-color: #99BACB !important; }

.bgc--secondary-1-darker {
  background-color: #81aabf !important; }

.bgc--secondary-1-lighter {
  background-color: #b1cad7 !important; }

.fc--secondary-1-light {
  color: #F8FCFE !important;
  fill: #F8FCFE !important; }

.fc--secondary-1-light-darker {
  color: #d9effa !important;
  fill: #d9effa !important; }

.fc--secondary-1-light-lighter {
  color: white !important;
  fill: white !important; }

.bgc--secondary-1-light {
  background-color: #F8FCFE !important; }

.bgc--secondary-1-light-darker {
  background-color: #d9effa !important; }

.bgc--secondary-1-light-lighter {
  background-color: white !important; }

.fc--secondary-2 {
  color: #31a175 !important;
  fill: #31a175 !important; }

.fc--secondary-2-darker {
  color: #298661 !important;
  fill: #298661 !important; }

.fc--secondary-2-lighter {
  color: #39bc89 !important;
  fill: #39bc89 !important; }

.bgc--secondary-2 {
  background-color: #31a175 !important; }

.bgc--secondary-2-darker {
  background-color: #298661 !important; }

.bgc--secondary-2-lighter {
  background-color: #39bc89 !important; }

.fc--secondary-2-light {
  color: #42ad83 !important;
  fill: #42ad83 !important; }

.fc--secondary-2-light-darker {
  color: #38936f !important;
  fill: #38936f !important; }

.fc--secondary-2-light-lighter {
  color: #55be95 !important;
  fill: #55be95 !important; }

.bgc--secondary-2-light {
  background-color: #42ad83 !important; }

.bgc--secondary-2-light-darker {
  background-color: #38936f !important; }

.bgc--secondary-2-light-lighter {
  background-color: #55be95 !important; }

.fc--gray-1 {
  color: #444444 !important;
  fill: #444444 !important; }

.fc--gray-1-darker {
  color: #323232 !important;
  fill: #323232 !important; }

.fc--gray-1-lighter {
  color: #565656 !important;
  fill: #565656 !important; }

.bgc--gray-1 {
  background-color: #444444 !important; }

.bgc--gray-1-darker {
  background-color: #323232 !important; }

.bgc--gray-1-lighter {
  background-color: #565656 !important; }

.fc--gray-2 {
  color: #717477 !important;
  fill: #717477 !important; }

.fc--gray-2-darker {
  color: #606265 !important;
  fill: #606265 !important; }

.fc--gray-2-lighter {
  color: #838689 !important;
  fill: #838689 !important; }

.bgc--gray-2 {
  background-color: #717477 !important; }

.bgc--gray-2-darker {
  background-color: #606265 !important; }

.bgc--gray-2-lighter {
  background-color: #838689 !important; }

.fc--gray-3 {
  color: #8a8d8f !important;
  fill: #8a8d8f !important; }

.fc--gray-3-darker {
  color: #787b7d !important;
  fill: #787b7d !important; }

.fc--gray-3-lighter {
  color: #9c9fa0 !important;
  fill: #9c9fa0 !important; }

.bgc--gray-3 {
  background-color: #8a8d8f !important; }

.bgc--gray-3-darker {
  background-color: #787b7d !important; }

.bgc--gray-3-lighter {
  background-color: #9c9fa0 !important; }

.fc--gray-4 {
  color: #97999b !important;
  fill: #97999b !important; }

.fc--gray-4-darker {
  color: #85878a !important;
  fill: #85878a !important; }

.fc--gray-4-lighter {
  color: #a9abad !important;
  fill: #a9abad !important; }

.bgc--gray-4 {
  background-color: #97999b !important; }

.bgc--gray-4-darker {
  background-color: #85878a !important; }

.bgc--gray-4-lighter {
  background-color: #a9abad !important; }

.fc--gray-5 {
  color: #a5a7a8 !important;
  fill: #a5a7a8 !important; }

.fc--gray-5-darker {
  color: #939596 !important;
  fill: #939596 !important; }

.fc--gray-5-lighter {
  color: #b7b9ba !important;
  fill: #b7b9ba !important; }

.bgc--gray-5 {
  background-color: #a5a7a8 !important; }

.bgc--gray-5-darker {
  background-color: #939596 !important; }

.bgc--gray-5-lighter {
  background-color: #b7b9ba !important; }

.fc--gray-6 {
  color: #b8b9bb !important;
  fill: #b8b9bb !important; }

.fc--gray-6-darker {
  color: #a6a7aa !important;
  fill: #a6a7aa !important; }

.fc--gray-6-lighter {
  color: #cacbcc !important;
  fill: #cacbcc !important; }

.bgc--gray-6 {
  background-color: #b8b9bb !important; }

.bgc--gray-6-darker {
  background-color: #a6a7aa !important; }

.bgc--gray-6-lighter {
  background-color: #cacbcc !important; }

.fc--gray-7 {
  color: #c6c7c9 !important;
  fill: #c6c7c9 !important; }

.fc--gray-7-darker {
  color: #b4b5b8 !important;
  fill: #b4b5b8 !important; }

.fc--gray-7-lighter {
  color: #d8d9da !important;
  fill: #d8d9da !important; }

.bgc--gray-7 {
  background-color: #c6c7c9 !important; }

.bgc--gray-7-darker {
  background-color: #b4b5b8 !important; }

.bgc--gray-7-lighter {
  background-color: #d8d9da !important; }

.fc--gray-8 {
  color: #d4d5d6 !important;
  fill: #d4d5d6 !important; }

.fc--gray-8-darker {
  color: #c2c3c5 !important;
  fill: #c2c3c5 !important; }

.fc--gray-8-lighter {
  color: #e6e7e7 !important;
  fill: #e6e7e7 !important; }

.bgc--gray-8 {
  background-color: #d4d5d6 !important; }

.bgc--gray-8-darker {
  background-color: #c2c3c5 !important; }

.bgc--gray-8-lighter {
  background-color: #e6e7e7 !important; }

.fc--gray-9 {
  color: #e3e3e4 !important;
  fill: #e3e3e4 !important; }

.fc--gray-9-darker {
  color: #d1d1d2 !important;
  fill: #d1d1d2 !important; }

.fc--gray-9-lighter {
  color: #f5f5f6 !important;
  fill: #f5f5f6 !important; }

.bgc--gray-9 {
  background-color: #e3e3e4 !important; }

.bgc--gray-9-darker {
  background-color: #d1d1d2 !important; }

.bgc--gray-9-lighter {
  background-color: #f5f5f6 !important; }

.fc--gray-10 {
  color: #f0f1f1 !important;
  fill: #f0f1f1 !important; }

.fc--gray-10-darker {
  color: #dee0e0 !important;
  fill: #dee0e0 !important; }

.fc--gray-10-lighter {
  color: white !important;
  fill: white !important; }

.bgc--gray-10 {
  background-color: #f0f1f1 !important; }

.bgc--gray-10-darker {
  background-color: #dee0e0 !important; }

.bgc--gray-10-lighter {
  background-color: white !important; }

.fc--error {
  color: #D8393C !important;
  fill: #D8393C !important; }

.fc--error-darker {
  color: #c6272a !important;
  fill: #c6272a !important; }

.fc--error-lighter {
  color: #de5759 !important;
  fill: #de5759 !important; }

.bgc--error {
  background-color: #D8393C !important; }

.bgc--error-darker {
  background-color: #c6272a !important; }

.bgc--error-lighter {
  background-color: #de5759 !important; }

.fc--error-fill {
  color: #FAE5E7 !important;
  fill: #FAE5E7 !important; }

.fc--error-fill-darker {
  color: #f4c7cb !important;
  fill: #f4c7cb !important; }

.fc--error-fill-lighter {
  color: white !important;
  fill: white !important; }

.bgc--error-fill {
  background-color: #FAE5E7 !important; }

.bgc--error-fill-darker {
  background-color: #f4c7cb !important; }

.bgc--error-fill-lighter {
  background-color: white !important; }

.fc--warning {
  color: #FF9904 !important;
  fill: #FF9904 !important; }

.fc--warning-darker {
  color: #df8500 !important;
  fill: #df8500 !important; }

.fc--warning-lighter {
  color: #ffa828 !important;
  fill: #ffa828 !important; }

.bgc--warning {
  background-color: #FF9904 !important; }

.bgc--warning-darker {
  background-color: #df8500 !important; }

.bgc--warning-lighter {
  background-color: #ffa828 !important; }

.fc--warning-fill {
  color: #fdedd6 !important;
  fill: #fdedd6 !important; }

.fc--warning-fill-darker {
  color: #fbdeb4 !important;
  fill: #fbdeb4 !important; }

.fc--warning-fill-lighter {
  color: #fffcf8 !important;
  fill: #fffcf8 !important; }

.bgc--warning-fill {
  background-color: #fdedd6 !important; }

.bgc--warning-fill-darker {
  background-color: #fbdeb4 !important; }

.bgc--warning-fill-lighter {
  background-color: #fffcf8 !important; }

.fc--success {
  color: #4a9864 !important;
  fill: #4a9864 !important; }

.fc--success-darker {
  color: #3e8054 !important;
  fill: #3e8054 !important; }

.fc--success-lighter {
  color: #58ae75 !important;
  fill: #58ae75 !important; }

.bgc--success {
  background-color: #4a9864 !important; }

.bgc--success-darker {
  background-color: #3e8054 !important; }

.bgc--success-lighter {
  background-color: #58ae75 !important; }

.fc--success-fill {
  color: #c4decd !important;
  fill: #c4decd !important; }

.fc--success-fill-darker {
  color: #add1ba !important;
  fill: #add1ba !important; }

.fc--success-fill-lighter {
  color: #dbebe0 !important;
  fill: #dbebe0 !important; }

.bgc--success-fill {
  background-color: #c4decd !important; }

.bgc--success-fill-darker {
  background-color: #add1ba !important; }

.bgc--success-fill-lighter {
  background-color: #dbebe0 !important; }

.fc--white {
  color: #ffffff !important;
  fill: #ffffff !important; }

.fc--white-darker {
  color: #ededed !important;
  fill: #ededed !important; }

.fc--white-lighter {
  color: white !important;
  fill: white !important; }

.bgc--white {
  background-color: #ffffff !important; }

.bgc--white-darker {
  background-color: #ededed !important; }

.bgc--white-lighter {
  background-color: white !important; }

/**
 *
 * TYPOGRAPHY: VARS
 *
 */
/**
 *
 * TYPOGRAPHY: FUNCTIONS
 *
 */
.chain {
  width: 100%;
  /*
	 *
	 * Define states */
  /*
	 *
	 * Define color variants */ }
  .chain .chain__list {
    display: flex;
    flex-direction: column-reverse; }
  .chain .chain__item {
    width: 100%;
    position: relative;
    padding: 18px 0;
    overflow: hidden; }
  .chain .chain__checkpoint {
    display: flex;
    min-height: 24px;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative; }
  .chain .chain__point {
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .chain .chain__point .chain__circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      background: #ffffff;
      color: #0C6196;
      border: 2px solid #0C6196;
      border-radius: 50%;
      overflow: hidden; }
      .chain .chain__point .chain__circle i {
        font-size: 16px;
        position: relative;
        z-index: 3; }
      .chain .chain__point .chain__circle svg {
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        width: 16px;
        height: 16px;
        max-width: 16px;
        max-height: 16px;
        fill: #0C6196; }
      .chain .chain__point .chain__circle:after {
        content: "";
        display: block;
        background: #137cbd;
        border-radius: 50%;
        opacity: 0;
        position: absolute;
        left: 3px;
        right: 3px;
        top: 3px;
        bottom: 3px;
        z-index: 2;
        transition: opacity 150ms; }
    .chain .chain__point:before, .chain .chain__point:after {
      content: "";
      display: block;
      width: 1px;
      height: 1000px;
      border-right: 2px solid #0C6196;
      position: absolute;
      left: 50%;
      z-index: 1;
      margin-left: -1px; }
    .chain .chain__point:before {
      bottom: 30px; }
    .chain .chain__point:after {
      top: 30px; }
  .chain .chain__content {
    font-size: 11px;
    line-height: 1.5;
    color: #717477;
    margin: 0 0 0 20px;
    padding: 2px 0 0 0; }
    .chain .chain__content .chain__heading {
      font-size: 14px;
      line-height: 1.1;
      color: #444444;
      margin: 0 0 5px; }
  .chain .chain__loader {
    display: block;
    width: 14px;
    height: 14px;
    background: #0C6196;
    border-radius: 50%; }
  .chain .chain__item.is-done .chain__point .chain__circle i {
    color: #ffffff; }
  .chain .chain__item.is-done .chain__point .chain__circle:after {
    opacity: 1; }
  .chain .chain__item.is-incomplete .chain__point:before, .chain .chain__item.is-incomplete .chain__point:after {
    border-right-style: dashed; }
  .chain .chain__item.is-incomplete + .chain__item .chain__point:after {
    border-right-style: dashed; }
  .chain .chain__item.is-progress .chain__loader {
    animation: progressRotation 750ms ease-in-out 0ms infinite alternate; }
  .chain .chain__item:first-child .chain__point:after {
    display: none; }
  .chain .chain__item:last-child .chain__point:before {
    display: none; }
  .chain .chain__item.color-warning .chain__point .chain__circle {
    color: #FF9904;
    border-color: #FF9904; }
    .chain .chain__item.color-warning .chain__point .chain__circle svg {
      fill: #FF9904; }
  .chain .chain__item.color-warning .chain__point:before, .chain .chain__item.color-warning .chain__point:after {
    border-right-color: #FF9904; }
  .chain .chain__item.color-warning + .chain__item .chain__point:after {
    border-right-color: #FF9904 !important; }
  .chain .chain__item.color-warning .chain__loader {
    background: #FF9904; }
  .chain .chain__item.color-error .chain__point .chain__circle {
    color: #D8393C;
    border-color: #D8393C; }
    .chain .chain__item.color-error .chain__point .chain__circle svg {
      fill: #D8393C; }
  .chain .chain__item.color-error .chain__point:before, .chain .chain__item.color-error .chain__point:after {
    border-right-color: #D8393C; }
  .chain .chain__item.color-error + .chain__item .chain__point:after {
    border-right-color: #D8393C !important; }
  .chain .chain__item.color-error .chain__loader {
    background: #D8393C; }
  .chain .chain__item.color-disabled .chain__point .chain__circle {
    color: #c6c7c9;
    border-color: #c6c7c9; }
    .chain .chain__item.color-disabled .chain__point .chain__circle svg {
      fill: #c6c7c9; }
  .chain .chain__item.color-disabled .chain__point:before, .chain .chain__item.color-disabled .chain__point:after {
    border-right-color: #c6c7c9; }
  .chain .chain__item.color-disabled + .chain__item .chain__point:after {
    border-right-color: #c6c7c9 !important; }
  .chain .chain__item.color-disabled .chain__loader {
    background: #c6c7c9; }

@keyframes progressRotation {
  from {
    transform: scale(1); }
  to {
    transform: scale(0.5); } }
